import type { AnalyticsState } from './reducer';
import type { LoadedSettings, SettingsState } from 'behavior/settings';
import { areSettingsLoaded } from 'behavior/settings';

type LoadedAnalyticsSettings = {
  analytics: NonNullable<AnalyticsState>;
  settings: LoadedSettings;
};

type AnalyticsSettings = {
  analytics: AnalyticsState;
  settings: SettingsState;
};

export function areAnalycticsSettingsLoaded(state: AnalyticsSettings): state is LoadedAnalyticsSettings {
  return areSettingsLoaded(state.settings) && !!state.analytics;
}
