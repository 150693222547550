import { ofType } from 'redux-observable';
import { PASSWORD_RESET_REQUESTED, notifyResetResult, ResetPasswordAction } from './actions';
import { exhaustMap, map, pluck } from 'rxjs/operators';
import { resetPasswordMutation } from './queries';
import { catchApiErrorWithToast } from 'behavior/errorHandling';
import { Epic } from 'behavior/types';

type ResetPasswordResponse = {
  profile: {
    resetPassword: {
      changed: boolean;
    };
  };
};

const resetPasswordEpic: Epic<ResetPasswordAction> = (action$, _state$, { api }) => {
  return action$.pipe(
    ofType(PASSWORD_RESET_REQUESTED),
    exhaustMap(({ payload }) => api.graphApi<ResetPasswordResponse>(resetPasswordMutation, { input: payload }).pipe(
      pluck('profile', 'resetPassword', 'changed'),
      map(notifyResetResult),
      catchApiErrorWithToast(),
    )),
  );
};

export default resetPasswordEpic;
