import type { CalculatedProductData, ProductData, Product, ProductSets, ProductSetState } from './types';
import type { ProductSetAction } from './actions';
import { PRODUCT_SET_RECEIVED } from './actions';
import { viewerChanged, VIEWER_CHANGED } from 'behavior/events';
import { deleteProductCalculatedInfo } from 'behavior/products/product';

type EventAction = ReturnType<
  | typeof viewerChanged
>;

export const initialState: ProductSetState = {
  productSets: {},
};

export default (state: ProductSetState = initialState, action: ProductSetAction | EventAction): ProductSetState => {
  switch (action.type) {
    case PRODUCT_SET_RECEIVED:
      const { contentBlockId, products, calculated } = action.payload;
      const stateProducts = getStateProducts(state, contentBlockId);

      const productSet = stateProducts && stateProducts.length && calculated
        ? { products: updateCalculatedInfo(stateProducts, products as CalculatedProductData[]), calculated: true }
        : { products: products as ProductData[], calculated: false };

      return {
        ...state,
        productSets: {
          ...state.productSets,
          [contentBlockId]: productSet,
        },
      };

    case VIEWER_CHANGED:
      if (!state.productSets)
        return state;

      const productSets: ProductSets = {};
      for (const key of Object.keys(state.productSets)) {
        const productSet = { ...state.productSets[key] };
        productSet.products = productSet.products.map(it => deleteProductCalculatedInfo<Product>(it));
        productSet.calculated = false;

        productSets[key] = productSet;
      }

      return { ...state, productSets };

    default:
      return state;
  }
};

const getStateProducts = (state: ProductSetState, contentBlockId: string) => {
  return state.productSets
    && state.productSets[contentBlockId]
    && state.productSets[contentBlockId].products;
};

const updateCalculatedInfo = (products: Array<Product>, calculatedProducts: Array<CalculatedProductData>) => {
  return products.map(product => {
    const calculated = calculatedProducts.find(({ id }) => id === product.id);
    return { ...product, ...calculated };
  });
};
