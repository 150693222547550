import PropTypes from 'prop-types';

const YoutubeThumbnail = ({ videoId, title }) => {
  const thumbnailUrl = getYoutubeThumbnailUrl(videoId);

  return <img src={thumbnailUrl} alt={title} />;
};

YoutubeThumbnail.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default YoutubeThumbnail;

function getYoutubeThumbnailUrl(id) {
  return `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
}
