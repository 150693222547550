import styles from './LargeGallery.module.scss';
import { useMemo, useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { YoutubePlayer, VimeoPlayer } from 'components/primitives/video';
import { VideoProviderType, pauseVideo } from 'utils/video';
import Spinner from 'components/primitives/spinner/Spinner';
import { resize$, useEventObservable } from 'utils/rxjs';
import { useSelector } from 'react-redux';
import { useVimeoNotFound } from '../hooks';
import { useShouldLoad } from './hooks';

function VideoSlide({ item, index, active, emitter }) {
  const noImage = useSelector(({ settings }) => settings.product.noImage);
  const [loaded, setLoaded] = useState(false);
  const shouldLoad = useShouldLoad(emitter, index, active);
  const playerContainerRef = useRef(null);
  const containerRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const pausedRef = useRef(false);

  const onLoad = useCallback(() => setLoaded(true), []);

  const { vimeoNotFound, onVimeoError } = useVimeoNotFound(onLoad);

  useEffect(() => setPlayerSize(containerRef, playerContainerRef), [shouldLoad]);

  useEffect(() => {
    if (active) {
      pausedRef.current = false;
    } else if (player && loaded && !pausedRef.current && !vimeoNotFound) {
      pauseVideo(player);
      pausedRef.current = true;
    }

  }, [active, vimeoNotFound]);

  useEventObservable(resize$, () => setPlayerSize(containerRef, playerContainerRef));

  const { videoData } = item;

  const eventHandlers = useMemo(() => {
    if (videoData.provider === VideoProviderType.Youtube)
      return {
        onReady: onLoad,
      };

    return {
      loaded: onLoad,
    };
  }, [videoData.provider]);

  const onInit = useCallback(player => setPlayer(player), []);

  return (
    <>
      {shouldLoad && (
        videoData.provider === VideoProviderType.Youtube
          ? (
            <div className={`${styles.slide} ${styles.videoSlide}`} ref={containerRef}>
              <YoutubePlayer
                videoId={videoData.videoId}
                enablejsapi="1"
                playsinline="1"
                modestbranding="1"
                events={eventHandlers}
                className={styles.video}
                ref={playerContainerRef}
                onInit={onInit}
              />
            </div>
          )
          : !vimeoNotFound
            ? (
              <div className={`${styles.slide} ${styles.videoSlide}`} ref={containerRef}>
                <VimeoPlayer
                  key={videoData.videoId}
                  videoId={videoData.videoId}
                  transparent={false}
                  events={eventHandlers}
                  className={styles.video}
                  ref={playerContainerRef}
                  onInit={onInit}
                  onError={onVimeoError}
                />
              </div>
            )
            : (
              <img
                className={styles.slide}
                src={noImage.large || noImage.medium || noImage.small}
                alt=""
                onLoad={onLoad}
                draggable="false"
                onDragStart={preventDragEvent}
              />
            )
      )}
      {!loaded &&
        <div className={`${styles.spinner} ${styles.slide}`}>
          <Spinner />
        </div>
      }
    </>);
}

VideoSlide.propTypes = {
  item: PropTypes.shape({
    videoData: PropTypes.shape({
      provider: PropTypes.oneOf([VideoProviderType.Vimeo, VideoProviderType.Youtube]),
      videoId: PropTypes.string.isRequired,
    }).isRequired,
  }),
  index: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  emitter: PropTypes.object,
};

export default VideoSlide;

function preventDragEvent(e) {
  e.preventDefault();
}

function setPlayerSize(containerRef, playerContainerRef) {
  if (!containerRef.current || !playerContainerRef.current)
    return;

  const {
    width: containerWidth,
    height: containerHeight,
  } = containerRef.current.getBoundingClientRect();

  if (containerWidth * 9 / 16 > containerHeight) {
    playerContainerRef.current.classList.add(styles.noFixedAspectRatio);
    playerContainerRef.current.style.height = containerHeight + 'px';
    playerContainerRef.current.style.width = (containerHeight * 16 / 9) + 'px';
  } else {
    playerContainerRef.current.classList.remove(styles.noFixedAspectRatio);
    playerContainerRef.current.style.height = '';
    playerContainerRef.current.style.width = '';
  }
}
