import { memo } from 'react';
import { useSanaTexts } from 'components/sanaText';
import { useSelector } from 'react-redux';
import { makeRichText } from 'utils/render';

const CopyrightTextBlock = () => {
  const { texts: [ footerCopyright, sanaCommerce ], loaded } = useSanaTexts(['Footer_Copyright', 'SanaCommerce']);
  const shopName = useSelector(state => state.settings.shopName);

  if (!loaded)
    return null;

  return (
    <span className="copyright">
      {/* Class 'copyright' is used in web tests. */}
      {makeRichText(footerCopyright, [new Date().getFullYear(), shopName, makeRichText(sanaCommerce)])}
    </span>
  );
};

export default memo(CopyrightTextBlock);
