import type { Middleware } from '../types';
import { RouteName } from 'routes';
import { redirect } from './helpers';

const canonicalRoutes = new Set([
  RouteName.ContentPage,
  RouteName.NewsDetail,
  RouteName.FaqPage,
  RouteName.ProductList,
  RouteName.Home,
]);

const canonicalMiddleware: Middleware = (route, location, dependencies) => {
  const { routeName, canonicalUrl } = route;

  if (!canonicalUrl || !canonicalRoutes.has(routeName))
    return;

  const routeData = { routeName, params: route.params };
  return redirect(canonicalUrl, location, routeData, dependencies);
};

export default canonicalMiddleware;
