import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { mergeMap, map } from 'rxjs/operators';
import { paymentErrorQuery } from './queries';
import { initSystemPageContent, loadSystemPageQuery } from 'behavior/pages/system';

export default (routeData, _state$, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi(loadSystemPageQuery('paymentError')).pipe(
      map(({ pages: { paymentError } }) => ({
        page: {
          ...paymentError,
          component: PageComponentNames.PaymentError,
          transaction: { id: '-', document: { id: '-' } },
        },
      })),
      initSystemPageContent(),
    );
  }

  if (!transactionId)
    return of(null);

  return api.graphApi(paymentErrorQuery, { id: transactionId }).pipe(
    mergeMap(({ pages: { paymentError }, paymentTransaction: transaction }) => {
      if (transaction == null)
        return of(null);

      transaction.id = transactionId;

      return of({
        page: {
          ...paymentError,
          component: PageComponentNames.PaymentError,
          transaction,
        },
      });
    }),
    initSystemPageContent(),
  );
};
