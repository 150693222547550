import { useMemo } from 'react';
import PropTypes from 'prop-types';
import SliderPopup from './SliderPopup';
import { ProductMediaType } from 'behavior/pages/product';

const LargeGallery = ({ items = [], index = 0, hide }) => {
  const filtered = useMemo(() => getLargeItemsWithIndex(items, index), [items, index]);

  return <SliderPopup hide={hide} items={filtered.items} index={filtered.index} />;
};

LargeGallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf([ProductMediaType.Image, ProductMediaType.Video]).isRequired,
    large: PropTypes.string,
  })),
  index: PropTypes.number,
  hide: PropTypes.func.isRequired,
};

export default LargeGallery;

/**
 *  Returns only large images or video from array and corresponding index of current item.
 * @param {array} items Array of images or video.
 * @param {number} initialIndex Index of media gallery initial item.
 * @param {number} activeIndex Index of media gallery current item.
 * @returns {object} Object with array of large images or video and index of current item in that array.
 */
function getLargeItemsWithIndex(items, initialIndex) {
  let largeIndex = 0;
  const largeItems = [];
  let indexInSlider = 0;

  for (let i = 0; i < items.length; i++) {
    const item = items[i];
    if (item.type === ProductMediaType.Image && !item.large)
      continue;

    largeItems.push(item);

    if (initialIndex === i)
      largeIndex = indexInSlider;

    indexInSlider++;
  }

  return {
    index: largeIndex,
    items: largeItems,
  };
}
