import loadable from '@loadable/component';

const SalesAgreements = loadable(() => import(/*webpackChunkName:"sales-agr"*/'./Agreements'));
const SalesAgreementDetails = loadable(() => import(/*webpackChunkName:"sales-agr"*/'./Details'));

/* eslint-disable react/no-multi-comp */
export function salesAgreementsRenderer(page) {
  return <SalesAgreements agreements={page.agreements} search={page.search} activeOnlyParam={page.activeOnly} />;
}

export function salesAgreementDetailsRenderer(page) {
  return <SalesAgreementDetails agreement={page.agreement} search={page.search} />;
}
