import type { RouteData } from 'routes';
import type { Middleware } from 'redux';
import { NavigationRequestedAction, NAVIGATION_REQUESTED } from './actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { unsetLoadingIndicator } from 'behavior/loadingIndicator';

type PostponeNavigationDelegate = (route: RouteData | undefined) => boolean;
type ContinuePendingAction = ReturnType<typeof continuePending>;

const subscribers: PostponeNavigationDelegate[] = [];
let pendingAction: NavigationRequestedAction | null = null;

const NAVIGATING_CONTINUE_PENDING = 'NAVIGATING_CONTINUE_PENDING' as const;
const continuePending = (action: NavigationRequestedAction) => ({
  type: NAVIGATING_CONTINUE_PENDING,
  payload: action,
});

export const omitNavigationMiddleware: Middleware = _store => next => (action: NavigationRequestedAction | ContinuePendingAction) => {
  switch (action.type) {
    case NAVIGATION_REQUESTED:
      if (!subscribers.length)
        return next(action);

      const routeData = action.payload.routeData;
      for (const shouldPostponeNavigation of subscribers) {
        if (shouldPostponeNavigation(routeData)) {
          pendingAction = action;
          return;
        }
      }

      return next(action);
    case NAVIGATING_CONTINUE_PENDING:
      pendingAction = null;
      return next(action.payload);
    default:
      return next(action);
  }
};

export const useOmitNavigation = (shouldPostponeNavigation: PostponeNavigationDelegate) => {
  useEffect(() => {
    subscribers.push(shouldPostponeNavigation);

    return () => {
      const index = subscribers.indexOf(shouldPostponeNavigation);
      subscribers.splice(index, 1);
    };
  }, [shouldPostponeNavigation]);

  const dispatch = useDispatch();
  const resume = () => {
    if (!pendingAction)
      return;

    dispatch(continuePending(pendingAction));
    pendingAction = null;
  };
  const discard = () => dispatch(unsetLoadingIndicator());

  return { resume, discard };
};
