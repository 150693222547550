import type { Product } from 'behavior/pages/productGroup';

type Specification = {
  key: string;
  name: string;
};

export const getSpecificationHeaders = (specifications: Array<Specification>, products: Array<Product>, maxAmount = 5) => {
  const productSpecifications = products.map(p => p.specifications);

  const specificationHeaders = specifications.filter(s =>
    productSpecifications.some(spec => spec && spec.find(p => p.key === s.key)),
  );

  return specificationHeaders.slice(0, maxAmount);
};
