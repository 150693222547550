import { memo } from 'react';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import Link from './base/Link';
import { useSelector } from 'react-redux';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';

const WishlistLink = ({ abilities: [wishlistAbility], ...attributes }) => {
  const isAuthenticated = useSelector(s => s.user.isAuthenticated);

  if (wishlistAbility == null)
    return null;

  let isAvailable = wishlistAbility === AbilityState.Available;

  if (!isAvailable && !isAuthenticated)
    isAvailable = wishlistAbility === AbilityState.Unauthorized;

  if (!isAvailable)
    return null;

  return (
    <Link {...attributes} to={routesBuilder.forWishList}>
      <SimpleText textKey="WishList" />
    </Link>
  );
};

WishlistLink.propTypes = {
  abilities: abilitiesPropType.isRequired,
};

export default memo(withAbilities(WishlistLink, [AbilityTo.UseWishlist]));
