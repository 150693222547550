export const insiteEditorReady = () => ({
  type: 'INSITE/WEBSTORE/READY' as const,
});

export const INSITE_EDITOR_SANA_TEXTS_REQUESTED = 'INSITE/SANA_TEXTS_REQUESTED' as const;
export const sanaTextRequested = (textKeys: string[]) => ({
  type: INSITE_EDITOR_SANA_TEXTS_REQUESTED,
  payload: { textKeys },
});

export const INSITE_EDITOR_SET_TEXT_ONLY_MODE = 'INSITE/SET_TEXT_ONLY_MODE' as const;
export const setTextOnlyMode = (textKeys: string[]) => ({
  type: INSITE_EDITOR_SET_TEXT_ONLY_MODE,
  payload: { textKeys },
});

export const INSITE_EDITOR_SAVE_SANA_TEXT = 'INSITE/SAVE_SANA_TEXT' as const;
export const saveSanaText = (languageId: number, { key, text }: { key: string; text: string }) => ({
  type: INSITE_EDITOR_SAVE_SANA_TEXT,
  payload: { languageId, sanaText: { key, text } },
});

export const INSITE_EDITOR_REVERT_SANA_TEXT = 'INSITE/REVERT_SANA_TEXT' as const;
export const revertSanaText = (languageId: number, key: string) => ({
  type: INSITE_EDITOR_REVERT_SANA_TEXT,
  payload: { languageId, key },
});

export const INSITE_EDITOR_DELETE_SANA_TEXT = 'INSITE/DELETE_SANA_TEXT' as const;
export const deleteSanaText = (languageId: number, key: string) => ({
  type: INSITE_EDITOR_DELETE_SANA_TEXT,
  payload: { languageId, key },
});

export const INSITE_EDITOR_EDIT_SANA_TEXT = 'INSITE/EDIT_SANA_TEXT' as const;
export const editSanaText = (languageId: number, key: string, isCustom: boolean, isEmptyText: boolean, isTextOnlyMode: boolean) => ({
  type: INSITE_EDITOR_EDIT_SANA_TEXT,
  payload: { key, languageId, isCustom, isEmptyText, isTextOnlyMode },
});

export const INSITE_EDITOR_SANA_TEXTS_LOADED = 'INSITE/SANA_TEXTS_LOADED' as const;
export const sanaTextsLoaded = (languageId: number, textKeys: string[]) => ({
  type: INSITE_EDITOR_SANA_TEXTS_LOADED,
  payload: { languageId, textKeys },
});

export const INSITE_EDITOR_LANGUAGE_CHANGED = 'INSITE/LANGUAGE_CHANGED' as const;
export const languageChanged = (languageId: number) => ({
  type: INSITE_EDITOR_LANGUAGE_CHANGED,
  payload: { languageId },
});

export const INSITE_EDITOR_ADD_CONSTRUCTED_TEXTS = 'INSITE/ADD_CONSTRUCTED_TEXTS' as const;
export const addConstructedTexts = (texts: { key: string; deleted: boolean }[]) => ({
  type: INSITE_EDITOR_ADD_CONSTRUCTED_TEXTS,
  payload: { texts },
});

export const INSITE_EDITOR_UPDATE_CONSTRUCTED_TEXT = 'INSITE/UPDATE_CONSTRUCTED_TEXT' as const;
export const updateConstructedText = ({ key, deleted }: { key: string; deleted: boolean }) => ({
  type: INSITE_EDITOR_UPDATE_CONSTRUCTED_TEXT,
  payload: { text: { key, deleted } },
});

export const INSITE_EDITOR_SET_SANA_TEXTS_INFO = 'INSITE/SET_SANA_TEXTS_INFO' as const;
export const INSITE_EDITOR_SANA_TEXT_REVERT_COMPLETED = 'INSITE/SANA_TEXT_REVERT_COMPLETED' as const;
export const INSITE_EDITOR_SANA_TEXT_DELETE_COMPLETED = 'INSITE/SANA_TEXT_DELETE_COMPLETED' as const;
export const INSITE_EDITOR_SANA_TEXT_SAVED = 'INSITE/SANA_TEXT_SAVED' as const;

export const INSITE_EDITOR_SANA_TEXT_CHANGED = 'INSITE/SANA_TEXT_CHANGED' as const;
export const sanaTextChanged = ({ key, text }: { key: string; text: string | null }) => ({
  type: INSITE_EDITOR_SANA_TEXT_CHANGED,
  payload: { key, text },
});

export const INSITE_EDITOR_LOADED = 'INSITE/LOADED' as const;
export const insiteEditorLoaded = () => ({
  type: INSITE_EDITOR_LOADED,
});

export const INSITE_EDITOR_RESOURCE_TEXTS_RECEIVED = 'INSITE/RESOURCE_TEXTS_RECEIVED' as const;
export const INSITE_EDITOR_SET_SETTINGS = 'INSITE/SET_SETTINGS' as const;

export type InsiteEditorAction = ReturnType<
  | typeof insiteEditorReady
  | typeof sanaTextRequested
  | typeof setTextOnlyMode
  | typeof saveSanaText
  | typeof revertSanaText
  | typeof deleteSanaText
  | typeof editSanaText
  | typeof sanaTextsLoaded
  | typeof languageChanged
  | typeof addConstructedTexts
  | typeof updateConstructedText
  | typeof sanaTextChanged
  | typeof insiteEditorLoaded
>;
