import type { DocumentsCollection } from './types';
import { DOCUMENTS_RECEIVED, DocumentsAction, DocumentsReceivedAction } from './actions';
import { createReducer } from 'utils/redux';

type State = {
  docs: DocumentsCollection | null;
};

export default createReducer<State, DocumentsAction>(null as any, {
  [DOCUMENTS_RECEIVED]: onDocumentsReceived,
});

function onDocumentsReceived(state: State, action: DocumentsReceivedAction) {
  const {
    documents,
    page,
  } = action.payload;

  if (!state.docs || page === 0 || !documents)
    return {
      ...state,
      docs: documents,
    };

  const { items, totalCount } = documents;
  return {
    ...state,
    docs: {
      items: state.docs.items.concat(items),
      totalCount,
    },
  };
}
