import { createReducer } from 'utils/redux';
import { DOC_BASED_RETURN_ORDER_SUBMITTED, DOC_BASED_RETURN_ORDER_FAILED } from './actions';

export type State = {
  isFailed?: true;
};

export default createReducer(null as unknown as State, {
  [DOC_BASED_RETURN_ORDER_SUBMITTED]: state => ({ ...state, isFailed: undefined }),
  [DOC_BASED_RETURN_ORDER_FAILED]: state => ({ ...state, isFailed: true as const }),
});
