import type { SalesAgreement, AgreementLineAvailability } from './types';

export const AGREEMENTS_REQUESTED = 'AGREEMENTS_REQUESTED' as const;
export const requestAgreements = (activeOnly: boolean, index = 0, size = 10) => ({
  type: AGREEMENTS_REQUESTED,
  payload: { activeOnly, index, size },
});
export const requestAgreement = (id: string) => ({
  type: AGREEMENTS_REQUESTED,
  payload: { id },
});

export const AGREEMENTS_RECEIVED = 'AGREEMENTS_RECEIVED' as const;
export const notifyAgreementsReceived = (agreements: SalesAgreement[] | null, append = false) => ({
  type: AGREEMENTS_RECEIVED,
  payload: { agreements, append },
});

export const AGREEMENTS_SEARCH_PRODUCT_IDS = 'AGREEMENTS_SEARCH_PRODUCT_IDS' as const;
export const searchForProductIds = (keywords: string) => ({
  type: AGREEMENTS_SEARCH_PRODUCT_IDS,
  payload: { keywords },
});

export const AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED = 'AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED' as const;
export const productIdsReceived = (keywords: string, ids: string[] | null) => ({
  type: AGREEMENTS_SEARCH_PRODUCT_IDS_RECEIVED,
  payload: { keywords, ids },
});

export const AGREEMENT_LINES_AVAILABILITY_REQUESTED = 'AGREEMENT_LINES_AVAILABILITY_REQUESTED' as const;
export const requestAgreementLinesAvailability = (agreement: SalesAgreement) => ({
  type: AGREEMENT_LINES_AVAILABILITY_REQUESTED,
  payload: agreement,
});

export const AGREEMENT_LINES_AVAILABILITY_RECEIVED = 'AGREEMENT_LINES_AVAILABILITY_RECEIVED' as const;
export const receiveAgreementLinesAvailability = (availableLines: AgreementLineAvailability[]) => ({
  type: AGREEMENT_LINES_AVAILABILITY_RECEIVED,
  payload: availableLines,
});

export type AgreementsListRequestAction = ReturnType<typeof requestAgreements>;
export type AgreementItemRequestAction = ReturnType<typeof requestAgreement>;

export type SalesAgreementsAction = ReturnType<
  | typeof requestAgreements
  | typeof requestAgreement
  | typeof notifyAgreementsReceived
  | typeof searchForProductIds
  | typeof productIdsReceived
  | typeof requestAgreementLinesAvailability
  | typeof receiveAgreementLinesAvailability
>;
