import type { PageTemplatesState } from './types';
import type { PageTemplateAction } from './actions';
import { PRODUCT_PAGE_TEMPLATE_LOADED } from './actions';
import { parseContent } from 'behavior/content';

const initialState = { product: null };

export default (state: PageTemplatesState = initialState, action: PageTemplateAction): PageTemplatesState => {
  switch (action.type) {
    case PRODUCT_PAGE_TEMPLATE_LOADED:
      return {
        ...state,
        product: {
          desktop: parseContent(action.payload.page.desktop),
          mobile: parseContent(action.payload.page.mobile),
          languageId: action.payload.languageId,
        },
      };
    default:
      return state;
  }
};
