import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { formatAsPrice } from 'utils/format';
import { useCurrencyInfo } from 'utils/hooks';
import { createPriceModel } from './priceUtils';

const emptyImages = {};
export const useProductDefaultImages = () => useSelector(s => s.settings.loaded ? s.settings.product.noImage : emptyImages);

export const useFormattedPrice = (price: number | null | undefined, format: (formattedPrice: string) => string) => {
  const currency = useCurrencyInfo();

  return useMemo(() => {
    if (price == null || !currency)
      return null;

    const formattedPrice = formatAsPrice(price, currency);
    return format ? format(formattedPrice) : formattedPrice;
  }, [price, currency]);
};

const emptyPriceModel = {};
export const usePriceModel = (salesPrice: number | null | undefined, basePrice: number) => {
  const actionPricesMode = useSelector(s => s.settings.loaded ? s.settings.product.actionPricesPresentationType : undefined);

  return useMemo(() => {
    if (salesPrice == null || !actionPricesMode)
      return emptyPriceModel;

    return createPriceModel(salesPrice, basePrice, actionPricesMode);
  }, [salesPrice, basePrice, actionPricesMode]);
};
