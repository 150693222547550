exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Video_wrapper{align-items:center;display:flex;justify-content:center;left:50%;overflow:hidden;transform:translate3d(-50%,0,0) scale(1.01)}.Video_wrapper,.Video_wrapper:after{height:100%;position:absolute;top:0;width:100%}.Video_wrapper:after{content:\"\";display:block;left:0}.Video_frame{flex-shrink:0;height:0;min-width:100%;padding-bottom:56.25%;position:relative;width:100%}.Video_frame>iframe{border:0;display:block;height:100%;left:0;margin:0;padding:0;position:absolute;top:0;width:100%}.Video_buffering{opacity:0;transition:opacity .5s}.Video_playing{opacity:1}@media print,screen{html.print .Video_wrapper{display:none}}", ""]);

// exports
exports.locals = {
	"videoFrameAspectRatio": ".5625",
	"wrapper": "Video_wrapper",
	"frame": "Video_frame",
	"buffering": "Video_buffering",
	"playing": "Video_playing"
};