import { loadCalculatedFieldsQuery } from '../queries';
import { createLastViewedProductsQuery } from './queries';
import { map, takeUntil, mergeMap, switchMap, filter, withLatestFrom } from 'rxjs/operators';
import { LOCATION_CHANGED } from 'behavior/events';
import {
  LASTVIEWED_PRODUCTS_PAGE_REQUESTED,
  pageReceived,
  requestPage,
} from './actions';
import { merge, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { createApiVariables, sortProducts } from 'behavior/products/lastViewedTracking';

export default (action$, state$, { api, localStorage }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));
  const empty$ = of(pageReceived([]));

  const all$ = action$.pipe(
    ofType(LASTVIEWED_PRODUCTS_PAGE_REQUESTED),
    filter(({ payload: { calculatedOnly } }) => !calculatedOnly),
    withLatestFrom(state$),
    switchMap(([_, state]) => {
      let variables = createApiVariables(localStorage);
      if (!variables)
        return empty$;

      variables = {
        ...variables,
        loadCategories: state.analytics && state.analytics.isTrackingEnabled,
      };
      variables.options.ignoreGrouping = true;

      return api.graphApi(createLastViewedProductsQuery({
        isInsiteEditor: state.insiteEditor.initialized,
      }), variables, { retries: 0 }).pipe(
        map(data => sortProducts(data.catalog.products.products, variables)),
        mergeMap(products => of(pageReceived(products), requestPage(true))),
        takeUntil(locationChanged$),
      );
    }),
  );

  const calculated$ = action$.pipe(
    ofType(LASTVIEWED_PRODUCTS_PAGE_REQUESTED),
    filter(({ payload: { calculatedOnly } }) => calculatedOnly),
    map(_ => createApiVariables(localStorage)),
    filter(Boolean),
    map(variables => ({
      ...variables,
      options: {
        ...variables.options,
        ignoreGrouping: true,
      },
    })),
    switchMap(variables => api.graphApi(loadCalculatedFieldsQuery, variables).pipe(
      map(data => pageReceived(data.catalog.products.products)),
      takeUntil(locationChanged$),
    )),
  );

  return merge(all$, calculated$);
};