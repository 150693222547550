import type { OrderAuthorizations } from './types';
import { OrderAuthorizationStatus } from '../document';

type OrderAuthorizationsFilterInput = {
  page: {
    index: number;
    size: number;
  };
  orderDate?: {
      from: string | null;
  } | undefined;
  authorizationStatus: OrderAuthorizationStatus | null;
};

export const ORDER_AUTHORIZATIONS_REQUESTED = 'ORDER_AUTHORIZATIONS_REQUESTED' as const;
export const requestAuthorizations = (options: OrderAuthorizationsFilterInput) => ({
  type: ORDER_AUTHORIZATIONS_REQUESTED,
  payload: { options },
});

export const ORDER_AUTHORIZATIONS_RECEIVED = 'ORDER_AUTHORIZATIONS_RECEIVED' as const;
export const authorizationsReceived = (orderAuthorizations: OrderAuthorizations | null, pageIndex: number) => ({
  type: ORDER_AUTHORIZATIONS_RECEIVED,
  payload: { orderAuthorizations, pageIndex },
});

export const AUTHORIZATIONS_PENDING_COUNT_REQUESTED = 'AUTHORIZATIONS_PENDING_COUNT_REQUESTED' as const;
export const requestPendingAuthorizationsCount = () => ({
  type: AUTHORIZATIONS_PENDING_COUNT_REQUESTED,
});

export const AUTHORIZATIONS_PENDING_COUNT_RECEIVED = 'AUTHORIZATIONS_PENDING_COUNT_RECEIVED' as const;
export const pendingAuthorizationsCountReceived = (count: number) => ({
  type: AUTHORIZATIONS_PENDING_COUNT_RECEIVED,
  payload: { count },
});

export type OrderAuthorizationsAction = ReturnType<
  | typeof requestAuthorizations
  | typeof authorizationsReceived
  | typeof requestPendingAuthorizationsCount
  | typeof pendingAuthorizationsCountReceived
>;