import animations from 'css/Animations.module.scss';
import { useState, useEffect, useRef } from 'react';
import { scroll$ } from 'utils/rxjs';
import { first, mergeMapTo } from 'rxjs/operators';
import { merge, of, fromEvent } from 'rxjs';
import { AnimationsMap } from './constants';
import { usePrintMode, useOnChange } from 'utils/hooks';
import { useIsVisualDesigner } from 'utils/hooks';

const isInView = blockRef => {
  if (!blockRef.current?.offsetParent)
    return false;

  const offsetParentRect = blockRef.current.offsetParent.getBoundingClientRect();
  const childHeight = blockRef.current.getBoundingClientRect()['height'];
  const scrollLimit = window.innerHeight / 3;
  const shift = childHeight < scrollLimit ? childHeight : scrollLimit;
  const topLine = offsetParentRect.top + blockRef.current.offsetTop + shift;

  return topLine < window.innerHeight;
};

export const useCssAnimation = (blockRef, animationName) => {
  const isVisualDesigner = useIsVisualDesigner();
  const [animatingClassName, setAnimatingClassName] = useState('');
  const isAnimationCompletedRef = useRef(false);
  const isAnimationExist = !!AnimationsMap[animationName];
  const isPrintMode = usePrintMode();

  useOnChange(() => {
    isAnimationCompletedRef.current = false;
  }, [isPrintMode], false);

  useEffect(() => {
    if (isVisualDesigner || isPrintMode || !blockRef.current || !isAnimationExist)
      return;

    const handleChildAnimationEnd = e => {
      if (e.target !== blockRef.current)
        return;

      isAnimationCompletedRef.current = true;
      setAnimatingClassName('');
    };

    const parentWithAnimation = blockRef.current.parentElement.closest('animate-element');
    const animationStarter$ = merge(of(1), scroll$);
    let actions$;

    blockRef.current.addEventListener('animationend', handleChildAnimationEnd);

    if (parentWithAnimation && !parentWithAnimation.classList.contains(animations.animated)) {
      actions$ = fromEvent(parentWithAnimation, 'animationend').pipe(
        mergeMapTo(animationStarter$),
      );
    } else {
      actions$ = animationStarter$;
    }

    const eventSubscription = actions$.pipe(
      first(() => isInView(blockRef)),
    ).subscribe(() => {
      setAnimatingClassName('animate-element-animating');
    });

    return () => {
      blockRef.current.removeEventListener('animationend', handleChildAnimationEnd);
      eventSubscription.unsubscribe();
    };
  }, [animationName, isVisualDesigner, isPrintMode]);

  if (!isVisualDesigner && !isPrintMode && isAnimationExist) {
    const filteredClasses = isAnimationCompletedRef.current ? 'animate-element-end' : `${AnimationsMap[animationName]} ${animatingClassName}`;
    return `animate-element ${filteredClasses}`;
  }

  return '';
};
