import { RouteName, routesBuilder } from 'routes';
import { makeSimpleText } from 'utils/render';
import { Link } from 'components/primitives/links';
import Schema from './Schema';

export function renderWithHomeLink(texts, { links, homePath, items }) {
  const homeRoute = routesBuilder.forHome();
  const homeLink = texts[0]
    ? <li><Link url={homePath} to={homeRoute}>{makeSimpleText(texts[0])}</Link></li>
    : null;
  return (
    <>
      <Schema nodes={items} homeLink={{ title: texts[0], link: { url: homePath, to: homeRoute } }} />
      {homeLink}
      {links}
    </>
  );
}

export function isHomePage({ link }, homePath) {
  if (!link)
    return false;

  if (link.to)
    return link.to.routeName === RouteName.Home;

  return link.url === homePath;
}