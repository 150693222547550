import { useSelector } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';

export function usePageTitle(title) {
  const shopName = useSelector(({ settings: { shopName } }) => shopName || '');
  const {
    texts: [separatorText],
    loaded: textsLoaded,
  } = useSanaTexts(['BrowserTitleSeparator'], makeSimpleText);

  if (!title)
    return shopName;

  return textsLoaded && shopName
    ? `${title} ${separatorText} ${shopName}`
    : title;
}

export function useEditorOnlyTexts(options) {
  if (!useSelector(state => state.visualDesigner.insiteEditor))
    return { texts: null, loaded: false };

  return useSanaTexts(options);
}