import loadable from '@loadable/component';
import { PageComponentNames } from 'behavior/pages';

const ProductComparisonPage = loadable(() => import(/*webpackChunkName:"product-comp"*/'./Page'));

export default {
  [PageComponentNames.ProductComparison]: page => (
    <ProductComparisonPage
      header={page.content?.header}
      footer={page.content?.footer}
      products={page.products}
      specifications={page.specifications}
      backTo={page.backTo}
    />
  ),
};
