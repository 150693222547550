import type { OrderAuthorizationsState } from './types';
import type { OrderAuthorizationsAction } from './actions';
import { ORDER_AUTHORIZATIONS_RECEIVED, AUTHORIZATIONS_PENDING_COUNT_RECEIVED } from './actions';
import { orderAuthResultReceived, ORDER_AUTHORIZATION_RESULT_RECEIVED } from 'behavior/pages/document';

type DocumentAction = ReturnType<
  | typeof orderAuthResultReceived
>;

const initialState: OrderAuthorizationsState = {
  orderAuthorizations: undefined,
  pendingAuthorizationsCount: undefined,
};

export default (state: OrderAuthorizationsState = initialState, action: OrderAuthorizationsAction | DocumentAction): OrderAuthorizationsState => {
  switch (action.type) {
    case ORDER_AUTHORIZATIONS_RECEIVED:
      if (action.payload.orderAuthorizations) {
        let {
          orderAuthorizations: {
            items,
            totalCount,
          },
          pageIndex,
        } = action.payload;

        if (pageIndex > 0 && items && state && state.orderAuthorizations)
          items = state.orderAuthorizations.items && state.orderAuthorizations.items.concat(items);

        return {
          ...state,
          orderAuthorizations: {
            items,
            totalCount,
          },
        };
      }
      return state;
    case AUTHORIZATIONS_PENDING_COUNT_RECEIVED:
      return {
        ...state,
        pendingAuthorizationsCount: action.payload.count,
      };
    case ORDER_AUTHORIZATION_RESULT_RECEIVED:
      return {
        ...state,
        pendingAuthorizationsCount: null,
      };

    default:
      return state;
  }
};
