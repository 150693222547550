import { Container } from 'components/primitives/grid';
import { BackToContainer } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { PageContent } from 'components/objects/pages';

function createSystemRenderer(titleTextKey, contentRenderer, backTo) {
  return page => (
    <>
      {backTo && <BackToContainer options={backTo} />}
      {titleTextKey &&
        <Container>
          <h1><SimpleText textKey={titleTextKey} /></h1>
        </Container>
      }
      <PageContent content={page.content?.header} position="header" />
      <Container>
        {contentRenderer(page)}
      </Container>
      <PageContent content={page.content?.footer} position="footer" />
    </>
  );
}

export default createSystemRenderer;
