exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ScrollToTopButton_wrapper{opacity:0;position:fixed;right:20px;top:calc(100% - 115px);transform:translateY(70px);transition:opacity .25s ease-in-out,transform .25s ease-in-out;-webkit-user-select:none;-ms-user-select:none;user-select:none;visibility:hidden;will-change:transform;z-index:9000000}.ScrollToTopButton_wrapper.ScrollToTopButton_visible{opacity:1;transform:translateY(0);visibility:visible}.ScrollToTopButton_scroll-top{background-color:var(--theme-button_BackgroundColor,#1f7bc9);border-color:var(--theme-button_BottomBorderColor,#1962a1);border-radius:50%;border-width:0 0 1px 0;cursor:pointer;height:45px;padding:0;width:45px}.ScrollToTopButton_scroll-top .ScrollToTopButton_icon{max-height:24px;max-width:24px}.ScrollToTopButton_scroll-top .ScrollToTopButton_icon .icon-general{fill:var(--theme-button_Color,#fff)}.ScrollToTopButton_scroll-top .ScrollToTopButton_icon .icon-shadow{fill:var(--theme-button_BottomBorderColor,#1962a1)}html.pointer-mouse .ScrollToTopButton_scroll-top:hover{background-color:var(--theme-button_Hover_BackgroundColor,#2289e3);border-color:var(--theme-button_Hover_BottomBorderColor,#1b6eb6)}html.pointer-mouse .ScrollToTopButton_scroll-top:hover .icon-shadow{fill:var(--theme-button_Hover_BottomBorderColor,#1b6eb6)}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .ScrollToTopButton_scroll-top:active{background-color:var(--theme-button_Hover_BackgroundColor,#2289e3);border-color:var(--theme-button_Hover_BottomBorderColor,#1b6eb6)}html:not(.pointer-mouse) .ScrollToTopButton_scroll-top:active .icon-shadow{fill:var(--theme-button_Hover_BottomBorderColor,#1b6eb6)}}}@media not all and (min-resolution:.001dpcm){@media (pointer:coarse){.ScrollToTopButton_scroll-top:hover{-webkit-tap-highlight-color:var(--theme-button_Hover_BackgroundColor,#2289e3)}}}@media print{.ScrollToTopButton_scroll-top{display:none}}", ""]);

// exports
exports.locals = {
	"wrapper": "ScrollToTopButton_wrapper",
	"visible": "ScrollToTopButton_visible",
	"scroll-top": "ScrollToTopButton_scroll-top",
	"scrollTop": "ScrollToTopButton_scroll-top",
	"icon": "ScrollToTopButton_icon"
};