import { subAccountPageQuery, newSubAccountPageQuery, getLoadSubAccountsPreviewPageQuery } from './queries';
import { initPageContent, SystemPage, SystemPageData } from '../system';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { arrayToObject } from 'utils/helpers';
import { RouteName } from 'routes';
import { PageComponentNames } from '../componentNames';
import type { Handler } from '../types';
import { SubAccountsOptions, SubAccountType } from './types';

type SubAccountRouteData = {
  routeName: RouteName.NewSubAccount | RouteName.EditSubAccount;
  params?: {
    id: string;
    previewToken?: string;
  };
};

type SubAccountPage = SystemPage & {
  component: PageComponentNames.SubAccount;
  subAccount?: SubAccountType;
  options: SubAccountsOptions;
  authorizers: Record<string,{
    id: string;
    name: string;
  }>;
};

type NewSubAccountSystemPageResponse = {
  pages: {
    newSubAccount: SystemPageData;
  };
};

type EditSubAccountSystemPageResponse = {
  pages: {
    editSubAccount: SystemPageData;
  };
};

type SubAccountPreviewSystemPageResponse = NewSubAccountPageResponse | EditSubAccountPageResponse;

type SubAccountPreviewPageResponse = SubAccountPreviewSystemPageResponse & {
  profile: {
    subAccounts: {
      options: SubAccountsOptions;
    };
  };
};

type EditSubAccountPageResponse = EditSubAccountSystemPageResponse & {
  profile: {
    subAccounts: {
      options: SubAccountsOptions;
      search: {
        list: SubAccountType[];
      };
      authorizers: {
        list: {
          id: string;
          name: string;
        }[];
      };
    };
    accountManagers: {
      list: {
        id: string;
        name: string;
      }[];
    };
  };
};

type NewSubAccountPageResponse = NewSubAccountSystemPageResponse & {
  profile: {
    subAccounts: {
      options: SubAccountsOptions;
    };
    accountManagers: {
      list: {
        id: string;
        name: string;
      }[];
    };
  };
};

const handler: Handler<SubAccountRouteData, SubAccountPage> = ({ routeName, params }, _state$, { api }) => {
  const isEdit = routeName === RouteName.EditSubAccount;
  const pageName = isEdit ? 'editSubAccount' : 'newSubAccount';

  if (params?.previewToken) {
    return api.graphApi<SubAccountPreviewPageResponse>(getLoadSubAccountsPreviewPageQuery(pageName)).pipe(
      map(({ pages, profile: { subAccounts } }) => {
        const page = 'editSubAccount' in pages ? pages.editSubAccount : pages.newSubAccount;
        if (!page)
          return null;

        const result = {
          page: initPageContent(page) as SubAccountPage,
        };

        result.page.component = PageComponentNames.SubAccount;
        result.page.options = subAccounts.options;
        result.page.authorizers = arrayToObject(Array.from(Array(3)).map((_, index) => ({
          id: index.toString(),
          name: `authorizer${index + 1}@sana-commerce.com`,
        })), a => a.id);

        if (isEdit)
          result.page.subAccount = {
            id: '',
            name: '',
            email: '',
            active: true,
            canOrder: true,
            canSeePrices: true,
            canSeeStock: true,
            canAuthorizeOrders: false,
            limits: [],
            dependentAccounts: [],
          };

        return result;
      }),
    );
  }

  const id = params?.id;

  if (isEdit && !id)
    return of(null);

  const request: Observable<EditSubAccountPageResponse | NewSubAccountPageResponse> = isEdit
    ? api.graphApi<EditSubAccountPageResponse>(subAccountPageQuery, { id })
    : api.graphApi<NewSubAccountPageResponse>(newSubAccountPageQuery);

  return request.pipe(
    map(({ pages, profile: { subAccounts, accountManagers } }) => {
      const page = 'editSubAccount' in pages ? pages.editSubAccount : pages.newSubAccount;
      if (!page || !subAccounts)
        return null;

      const result = {
        page: initPageContent(page) as SubAccountPage,
      };
      result.page.component = PageComponentNames.SubAccount;

      result.page.subAccount = 'search' in subAccounts ? subAccounts.search.list[0] : undefined;
      const authorizers = 'authorizers' in subAccounts
        ? accountManagers.list.concat(subAccounts.authorizers.list)
        : accountManagers.list;
      result.page.authorizers = arrayToObject(authorizers, a => a.id);

      if (result.page.subAccount)
        delete result.page.authorizers[result.page.subAccount.id];

      result.page.options = subAccounts.options;

      if (isEdit && !result.page.subAccount)
        return null;

      return result;
    }),
  );
};

export default handler;
