import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { mergeMap, exhaustMap } from 'rxjs/operators';
import { FEEDBACK_SUBMITTED, feedbackReceived, FeedbackAction } from './actions';
import { feedbackMutation } from './queries';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { resetCaptcha } from 'behavior/captcha';
import { unlockForm, FormName } from 'behavior/pages';

type FeedbackMutationResponse = {
  contactUs: boolean;
};

const feedbackEpic: Epic<FeedbackAction> = (action$, _, { api, logger }) => action$.pipe(
  ofType(FEEDBACK_SUBMITTED),
  exhaustMap(action => api.graphApi<FeedbackMutationResponse>(feedbackMutation, { data: action.payload }, { retries: 0 }).pipe(
    mergeMap(result => [feedbackReceived(result.contactUs), resetCaptcha(FormName.ContactUs), unlockForm(FormName.ContactUs)]),
    catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(FormName.ContactUs), unlockForm(FormName.ContactUs))),
    retryWithToast(action$, logger, _ => of(resetCaptcha(FormName.ContactUs), unlockForm(FormName.ContactUs))),
  )),
);

export default feedbackEpic;
