import styles from './Thumbnails.module.scss';
import PropTypes from 'prop-types';
import { ListItemIcon, ListSelectedItemIcon } from 'components/primitives/icons';
import { useResponsiveBreakpoints } from 'utils/layout';
import { usePrintMode } from 'utils/hooks';
import VideoThumbnail from './VideoThumbnail';
import { ProductMediaType } from 'behavior/pages/product';

const Thumbnail = ({ item, active }) => {
  const { xs } = useResponsiveBreakpoints();
  const isPrintMode = usePrintMode();

  if (xs && !isPrintMode) {
    const Icon = active ? ListSelectedItemIcon : ListItemIcon;

    return <Icon className={styles.icon} />;
  }

  return item.type === ProductMediaType.Video
    ? <VideoThumbnail item={item} />
    : <img src={item.small} alt={item.title} title={item.title} draggable="false" />;
};

Thumbnail.propTypes = {
  item: PropTypes.shape({
    small: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.oneOf([ProductMediaType.Video, ProductMediaType.Image]),
  }),
  active: PropTypes.bool,
};

export default Thumbnail;
