exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LoadingIndicator_loading-indicator{background-color:#f0f0f0;height:3px;left:0;overflow:hidden;position:fixed;top:0;transition:opacity .2s linear;width:100%;z-index:9800000}.LoadingIndicator_loading-indicator .LoadingIndicator_indicator-line{bottom:0;left:0;position:absolute;right:0;top:0;transform:translateX(-20%)}.LoadingIndicator_loading-indicator .LoadingIndicator_indicator-line:before{background-color:var(--theme-loadingIndicator_Color,#cb2245);bottom:0;content:\"\";left:0;position:absolute;top:0;width:20%}.LoadingIndicator_loading-indicator.LoadingIndicator_animate .LoadingIndicator_indicator-line{animation:LoadingIndicator_slideRight 1.5s ease-out infinite}.LoadingIndicator_loading-indicator.LoadingIndicator_animate .LoadingIndicator_indicator-line:before{animation:LoadingIndicator_pulse 1.5s linear infinite}@media print{.LoadingIndicator_loading-indicator{display:none}}@keyframes LoadingIndicator_slideRight{0%{transform:translateX(-20%)}to{transform:translateX(100%)}}@keyframes LoadingIndicator_pulse{0%,10%{transform:scaleX(1)}38%{transform:scaleX(2.5)}66%,to{transform:scaleX(1)}}", ""]);

// exports
exports.locals = {
	"loading-indicator": "LoadingIndicator_loading-indicator",
	"loadingIndicator": "LoadingIndicator_loading-indicator",
	"indicator-line": "LoadingIndicator_indicator-line",
	"indicatorLine": "LoadingIndicator_indicator-line",
	"animate": "LoadingIndicator_animate",
	"slideRight": "LoadingIndicator_slideRight",
	"pulse": "LoadingIndicator_pulse"
};