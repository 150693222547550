type InputLine = {
  lineNo: string;
  quantity: number;
  reasonId?: string;
  comment?: string;
};

type ReturnOrderInput = {
  reasonId?: string;
  comment?: string;
  id: string;
  orderId?: string;
  lines: InputLine[];
  fileNames?: string[];
};

export const DOC_BASED_RETURN_ORDER_SUBMITTED = 'DOC_BASED_RETURN_ORDER_SUBMITTED';
export const createReturnOrder = (input: ReturnOrderInput, files?: FileList) => ({
  type: DOC_BASED_RETURN_ORDER_SUBMITTED,
  payload: { input, files },
});

export const DOC_BASED_RETURN_ORDER_FAILED = 'DOC_BASED_RETURN_ORDER_FAILED';
export const setReturnOrderFailed = () => ({
  type: DOC_BASED_RETURN_ORDER_FAILED,
});

export type CreateDocBasedReturnOrderAction = ReturnType<
  | typeof createReturnOrder
  | typeof setReturnOrderFailed
>;
