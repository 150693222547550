//149978 [Roland DG Australia] 3.1. Adjust the order type in GP based on the delivery date.
import DeliveryDatePicker from './DeliveryDatePicker';
import { SimpleText } from 'components/sanaText';

const DeliveryDatePopup = () => {

  return (
    <>
      <h2><SimpleText textKey="OrderType_CurrentDeliveryDateChangeHeader" /></h2>
      <DeliveryDatePicker />
    </>
  );
};

export default DeliveryDatePopup;