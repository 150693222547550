import type { DomElement } from 'react-html-parser';
import { Fragment } from 'react';
import * as React from 'react';
import { splitToFragments } from 'utils/formatting';

export const transformWithFormat = (args: React.ReactNode[], node: DomElement, index: number): React.ReactNode => {
  if (!args || node.type !== 'text')
    return;

  const text = node.data;
  if (!text)
    return;

  const fragments = splitToFragments(text, args);
  if (fragments.length === 1)
    return fragments[0];

  return fragments.map((fragment, fragmentIndex) =>
    <Fragment key={`${index}_${fragmentIndex}`}>{fragment}</Fragment>);
};

export default (args: React.ReactNode[]) => transformWithFormat.bind(undefined, args);
