import { memo, ReactElement } from 'react';
import LinkButton from './LinkButton';
import { useSanaButton } from 'components/primitives/buttons';
import type { Props, SystemLinkProps, LinkProps } from './Link';
import type { SystemLinkTo } from './types';

type ExtraProps = {
  textKey: string;
  titleTextKey?: string;
  defaultText?: string;
  placeholder?: ReactElement | string;
};

type OmitProps = 'ref' | 'title';
type LinkButtonProps = Omit<Props, OmitProps> & ExtraProps;

function SanaLinkButton(props: Omit<LinkProps, OmitProps> & ExtraProps): ReactElement;
function SanaLinkButton(props: Omit<SystemLinkProps, OmitProps> & ExtraProps): ReactElement;
function SanaLinkButton({
  url,
  to,
  textKey,
  titleTextKey = textKey,
  defaultText,
  placeholder,
  ...attributes
}: LinkButtonProps) {
  const { text, title, loaded } = useSanaButton(textKey, defaultText, undefined, titleTextKey);
  if (!loaded)
    return placeholder || null;

  if (url)
    return <LinkButton url={url} to={to} {...attributes} title={title || undefined}>{text}</LinkButton>;

  return <LinkButton to={to as SystemLinkTo} title={title || undefined} {...attributes}>{text}</LinkButton>;
}

export default memo(SanaLinkButton) as typeof SanaLinkButton;
