import styles from './Gallery.module.scss';
import { memo, useEffect, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Spinner from 'components/primitives/spinner/Spinner';
import { styleTranslate } from '../helpers';

const ANIMATION_RATE = .1;
const INITIAL_ZOOM = .3;
const ZOOM_STEP = .1;
const ZOOM_MAX = 1;
const ZOOM_MIN = .1;
const BOUNDARY_GAP = 20;

const ZoomMedia = ({ src, initiatorNode }) => {
  if (!src)
    return;

  const zoomRef = useRef(null);
  const lenseRef = useRef(null);
  let ready = false;
  let animating = false;
  let animationFrameId;
  let zoom = INITIAL_ZOOM;

  const initiatorRect = initiatorNode.getBoundingClientRect();
  const initiatorRatio = initiatorRect.width / initiatorRect.height;
  const initiatorProps = {
    top: initiatorRect.top,
    left: initiatorRect.left,
    width: initiatorRect.width,
    height: initiatorRect.height,
  };

  const placerRect = getPlacerRect(initiatorRect);
  const placerRatio = placerRect.width / placerRect.height;

  const holderProps = {
    top: placerRect.top,
    left: placerRect.left,
    right: placerRect.right,
    width: (initiatorRatio > placerRatio) ? placerRect.width : placerRect.height * initiatorRatio,
    height: (initiatorRatio > placerRatio) ? placerRect.width / initiatorRatio : placerRect.height,
  };

  let currentZoomProps;
  let zoomProps;

  const holderStyles = {
    top: `${holderProps.top}px`,
    left: holderProps.left && `${holderProps.left}px`,
    right: holderProps.right && `${holderProps.right}px`,
    width: `${holderProps.width}px`,
    height: `${holderProps.height}px`,
  };

  const animateZoom = useCallback(() => {
    const deltaTop = zoomProps.top - currentZoomProps.top;
    const deltaLeft = zoomProps.left - currentZoomProps.left;
    const deltaWidth = zoomProps.width - currentZoomProps.width;

    currentZoomProps.top = currentZoomProps.top + deltaTop * ANIMATION_RATE;
    currentZoomProps.left = currentZoomProps.left + deltaLeft * ANIMATION_RATE;
    currentZoomProps.width = currentZoomProps.width + deltaWidth * ANIMATION_RATE;

    const zoomStyles = styleTranslate(currentZoomProps.left, currentZoomProps.top, 'px');
    zoomStyles.width = `${currentZoomProps.width}px`;
    Object.assign(zoomRef.current.style, zoomStyles);

    animationFrameId = requestAnimationFrame(animateZoom);
  }, []);

  const updateZoom = useCallback((mouseX, mouseY) => {
    const lenseProps = getLenseProps(mouseX, mouseY, initiatorProps, zoom);
    const lenseStyles = {
      top: `${lenseProps.top}px`,
      left: `${lenseProps.left}px`,
      width: `${lenseProps.width}px`,
      height: `${lenseProps.height}px`,
    };

    Object.assign(lenseRef.current.style, lenseStyles);

    const deltaX = (mouseX - initiatorProps.left) / initiatorProps.width;
    const deltaY = (mouseY - initiatorProps.top) / initiatorProps.height;
    zoomProps = getZoomProps(deltaX, deltaY, holderProps, zoom);

    if (!ready) {
      ready = true;
      zoomRef.current.parentElement.classList.add(styles.fadeIn);
      lenseRef.current.classList.add(styles.fadeIn);
    }

    if (!currentZoomProps) {
      currentZoomProps = { ...zoomProps };
      if (!animating) {
        animating = true;
        animateZoom();
      }
    }
  }, []);

  const handleMouseMove = useCallback(e => {
    updateZoom(e.clientX, e.clientY);
  }, []);

  const handleScroll = useCallback(e => {
    const event = window.event || e;
    const delta = event.wheelDelta || - event.detail;
    let newZoom = zoom;
    if (delta > 0)
      newZoom += ZOOM_STEP;
    if (delta < 0)
      newZoom -= ZOOM_STEP;
    if (newZoom > ZOOM_MAX)
      newZoom = ZOOM_MAX;
    if (newZoom < ZOOM_MIN)
      newZoom = ZOOM_MIN;

    if (zoom !== newZoom) {
      zoom = newZoom;
      updateZoom(e.clientX, e.clientY);
    }

    e.stopImmediatePropagation();
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const preventKeyDown = e => {
    const { target: { tagName, isContentEditable }, key } = e;
    const isInputElement = [
      'SELECT',
      'INPUT',
      'TEXTAREA',
    ].includes(tagName);

    if (isInputElement || isContentEditable)
      return;

    if (![' ', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(key))
      return;

    e.preventDefault();
  };

  const loaded = useCallback(() => {
    holderProps.height = holderProps.width * zoomRef.current.naturalHeight / zoomRef.current.naturalWidth;
    zoomRef.current.parentElement.style.height = holderProps.height + 'px';
    zoomRef.current.parentElement.classList.add('ready');
  }, []);

  useEffect(() => {
    if (!initiatorNode)
      return;

    initiatorNode.addEventListener('mousemove', handleMouseMove);
    initiatorNode.addEventListener('mousewheel', handleScroll);
    initiatorNode.addEventListener('DOMMouseScroll', handleScroll);

    return () => {
      initiatorNode.removeEventListener('mousemove', handleMouseMove);
      initiatorNode.removeEventListener('mousewheel', handleScroll);
      initiatorNode.removeEventListener('DOMMouseScroll', handleScroll);
    };
  }, [initiatorNode]);

  useEffect(() => {
    window.addEventListener('keydown', preventKeyDown);

    return () => {
      window.removeEventListener('keydown', preventKeyDown);

      if (animationFrameId)
        cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return ReactDOM.createPortal(
    <>
      <div className={styles.zoomLense} ref={lenseRef} />
      <div className={styles.zoom} style={holderStyles}>
        <div className={styles.spinner}><Spinner /></div>
        <img src={src} alt="" onLoad={loaded} ref={zoomRef} />
      </div>
    </>
    , document.getElementById('extras'));
};

ZoomMedia.propTypes = {
  src: PropTypes.string.isRequired,
  initiatorNode: PropTypes.object.isRequired,
};

export default memo(ZoomMedia);

function getPlacerRect(initiatorRect) {
  const rightSpace = window.innerWidth - initiatorRect.right;
  const isLeftSpaceBigger = initiatorRect.left > rightSpace;

  let top = initiatorRect.top;
  if (top < BOUNDARY_GAP)
    top = BOUNDARY_GAP;

  return {
    top,
    left: isLeftSpaceBigger ? null : initiatorRect.right + BOUNDARY_GAP,
    right: isLeftSpaceBigger ? window.innerWidth - initiatorRect.left + BOUNDARY_GAP : null,
    width: (isLeftSpaceBigger ? initiatorRect.left : rightSpace) - BOUNDARY_GAP * 3,
    height: window.innerHeight - top - BOUNDARY_GAP,
  };
}

function getLenseProps(x, y, props, zoom) {
  const width = props.width * zoom;
  const height = props.height * zoom;

  let top = y - height / 2;
  if (top < props.top)
    top = props.top;
  if (top > props.top + props.height - height)
    top = props.top + props.height - height;

  let left = x - width / 2;
  if (left < props.left)
    left = props.left;
  if (left > props.left + props.width - width)
    left = props.left + props.width - width;

  return { top, left, width, height };
}

function getZoomProps(x, y, holderProps, zoom) {
  const width = holderProps.width / zoom;
  const height = holderProps.height / zoom;

  let top = holderProps.height / 2 - height * y;
  if (top > 0)
    top = 0;
  if (top < holderProps.height - height)
    top = holderProps.height - height;

  let left = holderProps.width / 2 - width * x;
  if (left > 0)
    left = 0;
  if (left < holderProps.width - width)
    left = holderProps.width - width;

  return { top, left, width };
}
