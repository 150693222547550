import styles from './Dropdown.module.scss';
import { memo, useState, useRef, useEffect, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useEventObservable, resize$, scroll$, orientationChange$ } from 'utils/rxjs';

const defaultOptions = { show: false };

const Dropdown = ({
  children,
  header,
  bodyAlign,
  options = defaultOptions,
  onDropDownShow,
  onDropDownHide,
  hideCaretDown,
  disableAnimation,
  className,
  ...attributes
}) => {
  const { location } = useSelector(({ routing: { location } }) => ({ location }), shallowEqual);
  const isTabPressedRef = useRef(false);
  const dropDownRef = useRef();
  const focusedElementRef = useRef();
  const [offsetClasses, setOffsetClasses] = useState('');
  const [opened, setOpened] = useState(false);
  const [bodyMaxHeight, setBodyMaxHeight] = useState('none');

  const checkOffsets = () => {
    if (!dropDownRef.current)
      return;

    const contentWidth = window.innerWidth;
    const bodyRect = dropDownRef.current.firstElementChild.getBoundingClientRect();
    const dropDownRect = dropDownRef.current.getBoundingClientRect();

    const fromTopToEdge = dropDownRect.top;
    const fromBottomToEdge = window.innerHeight - fromTopToEdge - dropDownRect.height;

    const maxHeight = (fromTopToEdge > fromBottomToEdge ? fromTopToEdge : fromBottomToEdge) + 'px';
    if (maxHeight !== bodyMaxHeight)
      setBodyMaxHeight(maxHeight);

    const topOffsetHeight = bodyRect.height - dropDownRect.top;
    const bottomOffsetHeight = dropDownRect.bottom + bodyRect.height - window.innerHeight;
    const leftOffsetWidth = bodyRect.width - dropDownRect.left;
    const rightOffsetWidth = dropDownRect.right + bodyRect.width - contentWidth;

    const offsetLeft = leftOffsetWidth > 0 && leftOffsetWidth > rightOffsetWidth;
    const offsetRight = rightOffsetWidth > 0 && leftOffsetWidth < rightOffsetWidth;
    const offsetBottom = bottomOffsetHeight > 0 && topOffsetHeight < bottomOffsetHeight;
    const offsetTop = topOffsetHeight > 0 && topOffsetHeight > bottomOffsetHeight;
    const newOffsetClasses = joinClasses(
      offsetLeft && 'dropdown-offset-left',
      offsetRight && 'dropdown-offset-right',
      offsetBottom && 'dropdown-offset-bottom',
      offsetTop && 'dropdown-offset-top',
    );
    setOffsetClasses(newOffsetClasses);
  };

  useEffect(() => {
    const newOpened = !!options.show;
    setOpened(newOpened);
  }, [options]);

  useEffect(() => {
    if (!opened)
      return;

    const handleTouchStart = e => {
      if (!dropDownRef.current.contains(e.target))
        setOpened(false);
    };
    document.addEventListener('touchstart', handleTouchStart);

    if (document.activeElement && (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA'))
      dropDownRef.current.focus();

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
    };

  }, [opened]);

  useEventObservable(resize$, checkOffsets);
  useEventObservable(scroll$, checkOffsets);
  useEventObservable(orientationChange$, () => setOpened(false));

  useEffect(() => {
    setOpened(false);
  }, [location]);

  const onMouseOver = e => {
    if (opened || !dropDownRef.current.lastElementChild.contains(e.target) || isElementEmpty(dropDownRef.current.firstElementChild))
      return;

    setOpened(true);
    onDropDownShow && onDropDownShow();
  };

  const onMouseLeave = e => {
    // This fix is mostly for insite editor, but the issue is the using react portal and react synthetic events together.
    // The problem is that insite editor uses react portal for rendering froala inline editor instead of a text.
    // And when elements were replaced, the mouseLeave handler triggers when moving on child elements which created by react portal.
    // So there was added checking if 'relatedTarget' is a child of current dropdown.
    // 'relatedTarget' is an element which the cursor entered to after leaving handler owner.
    if (e.relatedTarget.closest && e.relatedTarget.closest('.' + styles.dropdown) === dropDownRef.current)
      return;

    setOpened(false);
    onDropDownHide && onDropDownHide();
  };

  const onClick = e => {
    attributes.onClick && attributes.onClick(e);
    if (opened || !dropDownRef.current.lastElementChild.contains(e.target) || isElementEmpty(dropDownRef.current.firstElementChild))
      return;

    setOpened(true);
    onDropDownShow && onDropDownShow();
  };

  const onFocus = () => {
    const isSwitchingFromAnotherTab = focusedElementRef.current === document.activeElement;
    focusedElementRef.current = document.activeElement;

    if (opened || isSwitchingFromAnotherTab || isElementEmpty(dropDownRef.current.firstElementChild))
      return;

    setOpened(true);
    onDropDownShow && onDropDownShow();
  };

  const onBlur = e => {
    const relatedTarget = e.relatedTarget || document.activeElement;
    focusedElementRef.current = relatedTarget;
    const keepDropdownOpened = !isTabPressedRef.current || e.currentTarget.contains(relatedTarget);
    if (keepDropdownOpened)
      return;

    setOpened(false);
    onDropDownHide && onDropDownHide();
  };

  const onKeyDown = e => {
    isTabPressedRef.current = e.key === 'Tab';
    if (e.key === 'Escape' || e.key === 'Esc') {
      setOpened(opened => {
        opened && onDropDownHide && onDropDownHide();
        return false;
      });
    } else if (e.key === 'Enter') {
      if (e.currentTarget !== e.target)
        return;

      setOpened(opened => {
        !opened && onDropDownShow && onDropDownShow();
        return true;
      });
    }
  };

  useEffect(() => {
    // Small timeout should be set to avoid actual rendered content is not fully occupy viewport size (width and/or height)
    // for example when we exiting print mode which results in wrong position calculations
    const timeoutId = setTimeout(checkOffsets, 0);

    if (isElementEmpty(dropDownRef.current.firstElementChild))
      dropDownRef.current.removeAttribute('tabindex');
    else
      dropDownRef.current.setAttribute('tabindex', '0');

    return () => clearTimeout(timeoutId);
  }, [children]);

  const dropdownClasses = joinClasses(
    'dropdown',
    styles.dropdown,
    offsetClasses,
    className,
    bodyAlign === 'right' && 'dropdown-align-right',
    opened && 'dropdown-opened',
    disableAnimation && styles.disableAnimation,
  );

  const caretDown = useMemo(() => !hideCaretDown && (
    <span className="dropdown-caret">
      <FontAwesomeIcon icon={faCaretDown} />
    </span>
  ), [hideCaretDown]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div aria-haspopup="true"
      {...attributes}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onKeyDown={onKeyDown}
      className={dropdownClasses}
      ref={dropDownRef}
    >
      <div className="dropdown-body" style={{ maxHeight: bodyMaxHeight }}>
        {children}
      </div>
      <div className="dropdown-header">
        {header}
        {caretDown}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  bodyAlign: PropTypes.string,
  options: PropTypes.shape({
    show: PropTypes.bool,
  }),
  onDropDownShow: PropTypes.func,
  onDropDownHide: PropTypes.func,
  hideCaretDown: PropTypes.bool,
  disableAnimation: PropTypes.bool,
  className: PropTypes.string,
};

export default memo(Dropdown);

function isElementEmpty(element) {
  if (!element || !element.childNodes)
    return true;

  return !element.childNodes.length;
}
