import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { orderPaymentPageQuery } from './queries';
import { loadSystemPageQuery, initSystemPageContent } from 'behavior/pages/system';

export default (routeData, _, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi(loadSystemPageQuery('orderPayment')).pipe(
      map(({ pages: { orderPayment } }) => ({
        page: {
          ...orderPayment,
          component: PageComponentNames.OrderPayment,
          transactionId: '',
          order: {
            id: '',
            currency: { id: '', symbol: '', decimalDigits: 0 },
            orderDate: '-',
            referenceNo: '-',
          },
          paymentMethods: Array.from(Array(3)).map((_, index) => ({
            id: index.toString(),
            name: '',
            isDefault: false,
          })),
        },
      })),
      initSystemPageContent(),
    );
  }

  return api.graphApi(orderPaymentPageQuery, { transactionId }).pipe(
    map(({ 
      pages: { orderPayment: orderPaymentPage },
      orderPayment: orderPaymentInfo,
    }) => {
      if (!orderPaymentInfo)
        return null;

      return {
        page: {
          transactionId,
          component: PageComponentNames.OrderPayment,
          ...handleOrderPaymentInfoResult(orderPaymentInfo),
          ...orderPaymentPage,
        },
      };
    }),
    initSystemPageContent(),
  );
};

function handleOrderPaymentInfoResult({ type, ...result }) {
  switch (type) {
    case 'OrderPaymentInfo':
      const { paymentMethods: { list: paymentMethods }, ...rest } = result;

      return {
        ...rest,
        paymentMethods: paymentMethods.map(({ info }) => info),
      };
    case 'UnavailableOrderPaymentInfo':
      const { failureReason, orderId } = result;
      return { failureFetching: { reason: failureReason, orderId } };
    default:
      throw new Error('Unexpected order payment fetching result.');
  }
}