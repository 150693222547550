import loadable from '@loadable/component';

const SubAccounts = loadable(() => import(/*webpackChunkName:"sub-acc"*/'./List'));
const SubAccount = loadable(() => import(/*webpackChunkName:"sub-acc"*/'./Details'));

/* eslint-disable react/no-multi-comp */
export function subAccountsRenderer(page) {
  return <SubAccounts list={page.subAccounts?.list} options={page.options} removalFailureInfo={page.removalFailureInfo} />;
}

export function subAccountRenderer(page) {
  return <SubAccount subAccount={page.subAccount} options={page.options} authorizers={page.authorizers} />;
}
