exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".LargeGallery_slide{bottom:0;left:0;margin:auto;max-height:95%;max-width:95%;position:absolute;right:0;top:0}.LargeGallery_spinner,.LargeGallery_video-slide{align-items:center;display:flex;justify-content:center}.LargeGallery_video-slide{bottom:32px;max-height:100%;max-width:100%;padding:0 57px}.LargeGallery_no-fixed-aspect-ratio{min-width:0;padding-bottom:0}", ""]);

// exports
exports.locals = {
	"slide": "LargeGallery_slide",
	"spinner": "LargeGallery_spinner",
	"video-slide": "LargeGallery_video-slide",
	"videoSlide": "LargeGallery_video-slide",
	"no-fixed-aspect-ratio": "LargeGallery_no-fixed-aspect-ratio",
	"noFixedAspectRatio": "LargeGallery_no-fixed-aspect-ratio"
};