import styles from './Gallery.module.scss';
import { forwardRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { YoutubePlayer, VimeoPlayer } from 'components/primitives/video';
import { VideoProviderType } from 'utils/video';
import { useVimeoNotFound } from '../hooks';

const GalleryVideo = forwardRef(({ videoData, onLoad, noImage, onPlayerChange }, ref) => {

  const { vimeoNotFound, setVimeoNotFound, onVimeoError } = useVimeoNotFound(onLoad);

  useEffect(() => {
    onPlayerChange(null);
  }, [vimeoNotFound]);

  useEffect(() => setVimeoNotFound(false), [videoData]);

  const eventHandlers = useMemo(() => {
    if (videoData.provider === VideoProviderType.Youtube)
      return {
        onReady: onLoad,
      };

    return {
      loaded: onLoad,
    };
  }, [videoData.provider]);

  return (
    <div ref={ref} className={styles.videoContainer}>
      {videoData.provider === VideoProviderType.Youtube
        ? (
          <div className={styles.video}>
            <YoutubePlayer
              videoId={videoData.videoId}
              enablejsapi="1"
              playsinline="1"
              modestbranding="1"
              events={eventHandlers}
              onInit={onPlayerChange}
            />
          </div>
        )
        : !vimeoNotFound
          ? (
            <div className={styles.video}>
              <VimeoPlayer
                key={videoData.videoId}
                videoId={videoData.videoId}
                transparent={false}
                events={eventHandlers}
                onInit={onPlayerChange}
                onError={onVimeoError}
              />
            </div>
          )
          : <img src={noImage} alt="" className={styles.noImage} onLoad={onLoad} />
      }
    </div>
  );
});

GalleryVideo.propTypes = {
  videoData: PropTypes.shape({
    provider: PropTypes.oneOf([VideoProviderType.Youtube, VideoProviderType.Vimeo]).isRequired,
    videoId: PropTypes.string.isRequired,
  }).isRequired,
  onLoad: PropTypes.func.isRequired,
  noImage: PropTypes.string.isRequired,
  onPlayerChange: PropTypes.func.isRequired,
};

export default GalleryVideo;
