import { CREATE_PROSPECT_PROCESSED, CreateProspectAction, CreationResult } from './actions';

type State = {
  createProspectResult?: CreationResult;
};

export default function reducer(state: State = {}, action: CreateProspectAction) {
  if (action.type === CREATE_PROSPECT_PROCESSED)
    return { ...state, createProspectResult: action.payload };

  return state;
}
