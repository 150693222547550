exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ModalDrawer_scrim{background-color:var(--theme-popup_Overlay_BackgroundColor,#000);bottom:0;left:0;opacity:0;position:fixed;right:0;top:0;transition:visibility .2s,opacity .2s ease-in;visibility:hidden;z-index:9300000}.ModalDrawer_scrim.ModalDrawer_expanded{opacity:.2;transition:visibility .25s,opacity .25s ease-in;visibility:visible}.ModalDrawer_container{background-color:var(--theme-page_BackgroundColor,#fff);bottom:0;box-shadow:8px 0 24px rgba(0,0,0,.25);display:flex;flex-direction:column;left:0;max-width:320px;opacity:0;overflow:hidden;position:fixed;top:0;transform:translateX(-100%);transition:transform .2s ease-in-out,opacity .15s ease-in .05s;width:100%;z-index:9300000}.ModalDrawer_container.ModalDrawer_expanded{opacity:1;transform:translateX(0);transition:transform .25s ease-in-out,opacity .2s ease-in .05s}.ModalDrawer_head{flex:0 0;line-height:0;text-align:right}.ModalDrawer_content{flex:1 1 auto;overflow:auto}.ModalDrawer_btn-close{background-color:transparent;border:0;border-radius:0;color:inherit;height:46px;margin:0;padding:0;vertical-align:bottom;width:46px}.ModalDrawer_btn-close svg{fill:currentColor;display:block;height:24px;margin:auto;width:24px}", ""]);

// exports
exports.locals = {
	"scrim": "ModalDrawer_scrim",
	"expanded": "ModalDrawer_expanded",
	"container": "ModalDrawer_container",
	"head": "ModalDrawer_head",
	"content": "ModalDrawer_content",
	"btn-close": "ModalDrawer_btn-close",
	"btnClose": "ModalDrawer_btn-close"
};