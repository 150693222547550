import styles from './ModalDrawer.module.scss';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { joinClasses } from 'utils/helpers';
import Swipe from 'react-easy-swipe';
import FocusLock from 'react-focus-lock';
import { useExtras } from 'utils/layout';
import { useFocusLockWhiteList } from 'utils/hooks';
import { CrossBigIcon } from 'components/primitives/icons';
import { useBodyScrollToggle } from 'components/objects/modals';

const ModalDrawer = ({ children, expanded, onClose, onClick, id, className, containerClassName, style }) => {
  const extras = useExtras();
  const whiteList = useFocusLockWhiteList();

  useBodyScrollToggle(expanded);

  const handleKeyUp = useCallback(e => {
    if (expanded && e.key === 'Escape')
      onClose();
  }, [onClose, expanded]);

  const drawerContent = (
    <FocusLock
      disabled={!expanded}
      returnFocus
      className={className}
      whiteList={whiteList}
      lockProps={{ style }}
    >
      {/* there is no need to handle key events on scrim; "escape" key event is handled on next div element (Swipe) */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={joinClasses(styles.scrim, expanded && styles.expanded)} onClick={onClose} />
      <Swipe
        onSwipeLeft={onClose}
        className={joinClasses(styles.container, expanded && styles.expanded, containerClassName)}
        id={id}
        tolerance={80}
        onKeyUp={handleKeyUp}
        onClick={onClick}
      >
        <div className={styles.head}>
          <button className={styles.btnClose} type="button" onClick={onClose}>
            <CrossBigIcon aria-hidden />
          </button>
        </div>
        {/* data-scroll-lock-scrollable - attribute that allows to scroll modal on touch devices */}
        <div className={styles.content} data-scroll-lock-scrollable>
          {children}
        </div>
      </Swipe>
    </FocusLock>
  );

  return extras ? ReactDOM.createPortal(drawerContent, extras) : null;
};

ModalDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  expanded: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  style: PropTypes.object,
};

export default ModalDrawer;
