import type { Epic } from 'behavior/types';
import type { ProductPageAction } from './actions';
import type {
  Product,
  CalculatedProduct,
  CalculatedProductVariant,
  ProductReview,
  VolumePrice,
  SalesAgreement,
  SalesAgreementLineAvailability,
} from './types';
import type { LoadedSettings } from 'behavior/settings';
import {
  updateProductCalculatedFieldsQuery,
  reviewsQuery,
  addReview,
  requestVolumePricesQuery,
  salesAgreementQuery,
} from './queries';
import {
  UPDATE_PRODUCT_CALCULATED_FIELDS,
  productCalculatedFieldsLoaded,
  REVIEWS_REQUESTED,
  reviewsReceived,
  REVIEW_SUBMITTED,
  reviewProcessed,
  VOLUME_PRICES_REQUESTED,
  volumePriceReceived,
  SALES_AGREEMENT_REQUESTED,
  receiveSalesAgreement,
} from './actions';
import { switchMap, map, takeUntil, exhaustMap, pluck, filter, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { rewriteTo } from 'behavior/routing';
import { LOCATION_CHANGED } from 'behavior/events';
import { routesBuilder } from 'routes';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import { EMPTY, merge, of } from 'rxjs';
import { resetCaptcha } from 'behavior/captcha';
import { unlockForm, FormName } from 'behavior/pages';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState, AbilityTo } from 'behavior/user/constants';

const productEpic: Epic<ProductPageAction> = (action$, state$, dependencies) => {
  const { api, logger } = dependencies;

  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  const onFieldsRequested$ = action$.pipe(
    ofType(UPDATE_PRODUCT_CALCULATED_FIELDS),
    switchMap(action => api.graphApi<CalculatedProductResponse>(updateProductCalculatedFieldsQuery, action.payload).pipe(
      map(mapResponseToAction),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  const onReviewsRequested$ = action$.pipe(
    ofType(REVIEWS_REQUESTED),
    exhaustMap(action => api.graphApi<ProductReviewsResponse>(reviewsQuery, action.payload).pipe(
      map(r => r.catalog?.products.products[0].reviews?.list),
      filter(r => !!r?.length),
      map(r => reviewsReceived(r!)),
      takeUntil(locationChanged$),
    )),
  );

  const reviewProcessedAction = reviewProcessed(true);
  const onReviewSubmitted$ = action$.pipe(
    ofType(REVIEW_SUBMITTED),
    exhaustMap(action => api.graphApi(addReview, { data: action.payload }).pipe(
      mergeMap(_ => [reviewProcessedAction, resetCaptcha(FormName.Review), unlockForm(FormName.Review)]),
      catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptcha(FormName.Review), unlockForm(FormName.Review))),
      retryWithToast(action$, logger, _ => of(resetCaptcha(FormName.Review), unlockForm(FormName.Review))),
      takeUntil(locationChanged$),
    )),
  );

  const onVolumePricesRequested$ = action$.pipe(
    ofType(VOLUME_PRICES_REQUESTED),
    switchMap(action => api.graphApi<VolumePricesResponse>(requestVolumePricesQuery, action.payload).pipe(
      filter(r => !!r.catalog?.volumePrices),
      map(r => {
        const volumePrices = r.catalog!.volumePrices!;
        const { variantId, uomId } = action.payload;

        return volumePriceReceived({ prices: volumePrices, variantId, uomId });
      }),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  const onAgreementTermsRequested$ = action$.pipe(
    ofType(SALES_AGREEMENT_REQUESTED),
    pluck('payload'),
    switchMap(({ agreementId, productId }) => api.graphApi<SalesAgreementResponse>(salesAgreementQuery, { agreementId, productIds: [productId] }).pipe(
      filter(r => !!r.salesAgreements?.agreement),
      switchMap(({ salesAgreements }) => requestAbility(AbilityTo.ViewUnitOfMeasure, state$, dependencies).pipe(
        map(canViewUomAbility => {
          const { uom, uoms } = (state$.value.page as ProductPage).product;
          return receiveSalesAgreement(
            productId,
            salesAgreements!.agreement!,
            salesAgreements!.linesAvailability,
            canViewUomAbility === AbilityState.Available,
            (state$.value.settings as LoadedSettings).product.allowUOMSelection,
            uom,
            uoms,
          );
        }),
      )),
      catchError(_ => {
        logger.warn('Could not retrieve sales agreement terms for the product. '
          + 'The agreement is specified in the basket but the server returned no agreement terms. The server might be in offline mode.');
        return EMPTY;
      }),
      takeUntil(locationChanged$),
    )),
  );

  return merge(
    onFieldsRequested$,
    onReviewsRequested$,
    onReviewSubmitted$,
    onVolumePricesRequested$,
    onAgreementTermsRequested$,
  );
};

export default productEpic;

function mapResponseToAction(data: CalculatedProductResponse) {
  const product = data.catalog?.products.products[0];
  if (!product)
    return rewriteTo(routesBuilder.forNotFound());

  // User has no OrderProducts ability so actual isOrderable was not calculated/requested from ERP.
  if (!('isOrderable' in product)) {
    product.isOrderable = true;
    if (product.variants)
      for (const variant of product.variants)
        variant.isOrderable = true;
  }

  return productCalculatedFieldsLoaded(product as CalculatedProduct);
}

type ProductPage = {
  product: Product;
};

type CalculatedProductVariantData = Omit<CalculatedProductVariant, 'isOrderable'> & {
  isOrderable?: boolean;
};

type CalculatedProductData = Omit<CalculatedProduct, 'isOrderable' | 'variants'> & {
  isOrderable?: boolean;
  variants: CalculatedProductVariantData[] | null;
};

type CalculatedProductResponse = {
  catalog: {
    products: {
      products: [CalculatedProductData];
    };
  } | null;
};

type ProductReviewsResponse = {
  catalog: {
    products: {
      products: [{
        reviews: {
          list: ProductReview[];
        } | null;
      }];
    };
  } | null;
};

type VolumePricesResponse = {
  catalog: {
    volumePrices: VolumePrice[] | null;
  } | null;
};

type SalesAgreementResponse = {
  salesAgreements: {
    agreement: SalesAgreement | null;
    linesAvailability: SalesAgreementLineAvailability[] | null;
  } | null;
};
