export const WISH_LIST_ADD_PRODUCT = 'WISH_LIST_ADD_PRODUCT' as const;
export const addProductToWishList = (productId: string, uomId?: string, variantId?: string) => ({
  type: WISH_LIST_ADD_PRODUCT,
  payload: { productId, uomId, variantId },
});

export const WISH_LIST_ADD_BASKET = 'WISH_LIST_ADD_BASKET' as const;
export const addBasketToWishList = () => ({
  type: WISH_LIST_ADD_BASKET,
});

export const WISH_LIST_UPDATED = 'WISH_LIST_UPDATED' as const;
export const updateWishList = (modifiedDate: number) => ({
  type: WISH_LIST_UPDATED,
  payload: { modifiedDate },
});

export const WISH_LIST_REMOVE_PRODUCT = 'WISH_LIST_REMOVE_PRODUCT' as const;
export const removeProductFromWishList = (productId: string, uomId?: string, variantId?: string) => ({
  type: WISH_LIST_REMOVE_PRODUCT,
  payload: { productId, uomId, variantId },
});

export const WISH_LIST_PRODUCT_REMOVED = 'WISH_LIST_PRODUCT_REMOVED' as const;
export const productRemovedFromWishList = ({ productId, uomId, variantId }: { productId: string; uomId?: string; variantId?: string }) => ({
  type: WISH_LIST_PRODUCT_REMOVED,
  payload: { productId, uomId, variantId },
});

export const WISH_LIST_MOVE_PRODUCT_TO_BASKET = 'WISH_LIST_MOVE_PRODUCT_TO_BASKET' as const;
export const moveProductToBasket = (productId: string, uomId?: string, variantId?: string) => ({
  type: WISH_LIST_MOVE_PRODUCT_TO_BASKET,
  payload: { productId, uomId, variantId },
});

export type UpdateWishListAction = ReturnType<typeof updateWishList>;
export type ProductRemovedFromWishListAction = ReturnType<typeof productRemovedFromWishList>;

export type WishListAction =
  | UpdateWishListAction
  | ProductRemovedFromWishListAction
  | ReturnType<
    | typeof addProductToWishList
    | typeof addBasketToWishList
    | typeof updateWishList
    | typeof removeProductFromWishList
    | typeof productRemovedFromWishList
    | typeof moveProductToBasket
  >;
