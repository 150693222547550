import { loadProductListPageQuery } from './queries';
import { tap, map, first, switchMap, pluck } from 'rxjs/operators';
import { of } from 'rxjs';
import { initComponent } from 'behavior/pages/helpers';
import { parseContent } from 'behavior/content';
import { ProductSpecificationFilter } from 'behavior/products/product';
import { sortOptionsAreEqual, getViewModeProductAmount } from './helpers';
import { requestProductsGeneralInfo } from 'behavior/pages/productList/actions';
import { PageComponentNames } from '../componentNames';

export default ({ params, options }, state$, { api, scope }) => {
  const { id } = params;
  if (!id)
    return of(null);

  const handle = ({ settings }) => {
    const viewMode = params.viewMode;
    const loadOptions = createLoadOptions(params, { ...options, viewMode }, settings.productList);

    const state = state$.value;
    if (options && options.productsOnly) {
      return of({
        action$: of(requestProductsGeneralInfo(id, loadOptions, options.appendProducts)),
        page: state.page,
      });
    }

    return api.graphApi(loadProductListPageQuery({
      isInsiteEditor: state.insiteEditor.initialized,
      isProductGroupingEnabled: settings.product.productGrouping.isEnabled,
    }), {
      id,
      options: loadOptions,
      specificationFilter: ProductSpecificationFilter.ForList,
      loadLargeImages: scope === 'SERVER',
      loadCategories: state.analytics.isTrackingEnabled,
    }).pipe(
      pluck('pages', 'productList'),
      initComponent(PageComponentNames.ProductList),
      tap(result => parsePageContent(result, params)),
      map(result => {
        const page = result && result.page;
        if (!page || !page.facets)
          return null;

        page.lastViewedEnabled = settings.lastViewedEnabled;
        page.products.forEach(p => {
          p.defaultUom = p.uom;
        });

        return { page };
      }),
    );
  };

  return state$.pipe(
    first(({ settings, analytics }) => settings.loaded && !!analytics),
    switchMap(handle),
  );
};

function parsePageContent(result, params) {
  const page = result && result.page;

  if (!page)
    return;

  normalizePage(page);
  if (page.headerContent)
    page.headerContent = parseContent(page.headerContent);

  if (page.footerContent)
    page.footerContent = parseContent(page.footerContent);

  const { sort, viewMode, page: index, facets, id } = params;

  page.selectedSorting = sort || page.defaultSorting;
  page.selectedViewMode = viewMode || page.defaultViewMode;
  page.id = id;

  // Prevent page indexing by robots in case of
  // sorting or view mode is not default
  // or paging is present in URL.
  // or selected facet is not crawlable
  page.index = (!sort || sortOptionsAreEqual(sort, page.defaultSorting))
    && page.selectedViewMode === page.defaultViewMode
    && !index
    && checkIfFacetCrawlable(facets, page.facets?.facets);
}

function checkIfFacetCrawlable(selectedFacets, facets) {
  if (!facets)
    return true;

  for (const facetName in selectedFacets) {
    if (!selectedFacets[facetName].length)
      continue;

    const selectedFacet = facets.find(facet => facet.name === facetName);
    if (!selectedFacet.crawlable)
      return false;
  }

  return true;
}

export function createLoadOptions(params, options, settings) {
  const { sort, facets, q } = params;

  const paramsPage = params.page;
  const page = options.page || (paramsPage ? paramsPage - 1 : 0);
  const count = options.size || params.count;
  const viewMode = options.viewMode;

  const loadOptions = {
    page: {
      index: page || 0,
      size: count || getViewModeProductAmount(viewMode, settings),
    },
  };

  if (sort)
    loadOptions.sorting = [sort];

  if (facets) {
    const facetsArr = Object.entries(facets).map(([name, values]) => ({ name, values }));
    if (facetsArr.length)
      loadOptions.facets = { filter: facetsArr };
  }

  if (q)
    loadOptions.keywords = q;

  return loadOptions;
}

function normalizePage(page) {
  const { products, totalCount, facets } = page.products;

  page.products = products;
  page.totalCount = totalCount;
  page.facets = facets;
}
