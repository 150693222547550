import type { DocumentType } from 'behavior/documents';
import { DocumentsCollection } from './types';

type Options = {
  page: {
    index: number;
    size: number;
  };
};

export const DOCUMENTS_REQUESTED = 'DOCUMENTS_REQUESTED' as const;
export const requestDocuments = (options: Options, documentType: DocumentType) => ({
  type: DOCUMENTS_REQUESTED,
  payload: { options, documentType },
});

export const DOCUMENTS_RECEIVED = 'DOCUMENTS_RECEIVED' as const;
export const documentsReceived = (documents: DocumentsCollection | null, page: number) => ({
  type: DOCUMENTS_RECEIVED,
  payload: { documents, page },
});

export type DocumentsReceivedAction = ReturnType<typeof documentsReceived>;
export type DocumentsAction = ReturnType<typeof requestDocuments> | DocumentsReceivedAction;
