import styles from './LanguageSelector.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import useLanguageSelectorBehavior from './useLanguageSelectorBehavior';
import { Dropdown } from 'components/primitives/dropdown';
import Header from './Header';
import Body from './Body';
import { Placeholder } from 'components/primitives/placeholders';
import { getThemeFontSizeClassName } from 'components/theme';

const DesktopLanguageSelectorBlock = ({ model, isDesignerMode }) => {
  const {
    languagesReady,
    selectedLanguage,
    sortedLanguages,
    alternateUrls,
    loadAlternateUrls,
  } = useLanguageSelectorBehavior();

  if (!languagesReady || (sortedLanguages.length <= 1 && !isDesignerMode))
    return null;

  const toggleFontSizeClassName = getThemeFontSizeClassName(model.toggle_FontSize);
  const dropdownItemFontSizeClassName = getThemeFontSizeClassName(model.content_ItemFontSize);

  const style = {
    '--toggle-fontFamily': model.toggle_FontFamily && (model.toggle_FontFamily + ', sans-serif'),
    '--toggle-allCaps': model.toggle_AllCaps ? 'uppercase' : null,
    '--toggle-fontColor': model.toggle_FontColor,
    '--content-allCaps': model.content_AllCaps ? 'uppercase' : null,
    '--content-backgroundColor': model.content_BackgroundColor,
    '--content-itemHoverBackgroundColor': model.content_ItemHoverBackgroundColor,
    '--content-borderColor': model.content_BorderColor,
    '--content-itemFontColor': model.content_ItemFontColor,
    '--content-itemHoverFontColor': model.content_ItemHoverFontColor,
  };

  return (
    <Dropdown
      className={styles.dropdown}
      onDropDownShow={loadAlternateUrls}
      hideCaretDown={!model.content_ShowExpandIcon}
      header={<Header selectedLanguage={selectedLanguage} presentation={model.toggle_Presentation} className={toggleFontSizeClassName} />}
      style={style}
    >
      {!isDesignerMode && (
        alternateUrls && alternateUrls.length
          ? (
            <Body
              selectedLanguage={selectedLanguage}
              languages={sortedLanguages}
              alternateUrls={alternateUrls}
              showFlags={model.content_ShowFlags}
              className={dropdownItemFontSizeClassName}
            />
          )
          : (
            <div className={styles.placeholder}>
              <Placeholder lineCount={sortedLanguages.length} className={styles.placeholderItem} />
            </div>
          )
      )}
    </Dropdown>
  );
};

DesktopLanguageSelectorBlock.propTypes = {
  model: PropTypes.shape({
    toggle_Presentation: PropTypes.number.isRequired,
    toggle_FontFamily: PropTypes.string,
    toggle_FontSize: PropTypes.string.isRequired,
    toggle_AllCaps: PropTypes.bool.isRequired,
    toggle_FontColor: PropTypes.string,
    content_ShowFlags: PropTypes.bool.isRequired,
    content_AllCaps: PropTypes.bool.isRequired,
    content_ShowExpandIcon: PropTypes.bool.isRequired,
    content_BackgroundColor: PropTypes.string,
    content_ItemHoverBackgroundColor: PropTypes.string,
    content_BorderColor: PropTypes.string,
    content_ItemFontSize: PropTypes.string.isRequired,
    content_ItemFontColor: PropTypes.string,
    content_ItemHoverFontColor: PropTypes.string,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default memo(DesktopLanguageSelectorBlock);
