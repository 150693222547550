import styles from './Error.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import { Link } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';
import { useSanaTexts } from 'components/sanaText';
import { DangerAlert, alertStyles } from 'components/primitives/alerts';
import { routesBuilder } from 'routes';
import { makeSimpleText, makeRichText } from 'utils/render';
import ErrorButton from './ErrorButton';
import { isBrowser } from 'utils/detections';

const textKeys = [
  'ErrorPage_PageErrorTitle',
  'ErrorPage_ErrorText',
  'ErrorPage_PageErrorReload_Reload',
  'ErrorPage_PageErrorReload_Text',
  'ErrorPages_HomepageLink',
  'ErrorPages_HomepageLinkTemplate',
  'ErrorPage_ErrorId',
];

const ErrorBanner = ({ timeId }) => {
  const { loaded, texts: [title, msg, reload, reloadTemplate, returnText, returnHomeTemplate, errorIdText] } = useSanaTexts(textKeys);

  const placeholder = loaded ? '' : <Placeholder className={styles.loading} />;

  const retryLoading = (
    <button className={alertStyles.link} onClick={_ => window.location.reload()}>
      {makeSimpleText(reload) || 'Reload'}
    </button>
  );

  const homePageLink = (
    <Link to={routesBuilder.forHome}>
      {makeSimpleText(returnText) || 'Return'}
    </Link>
  );

  const timeIdNode = timeId && (ErrorButton.canCopy || !isBrowser
    ? <ErrorButton id={timeId} className={alertStyles.link} />
    : `'${timeId}'`
  );

  return (
    <Container id="errorPage">
      <h1>
        {placeholder || makeSimpleText(title) || 'Requested page cannot be shown'}
      </h1>
      <DangerAlert className={styles.block}>
        {timeIdNode &&
          <div>
            {placeholder || makeRichText(errorIdText || 'Please contact our support and communicate the following ID to them: {0}.', [timeIdNode])}
          </div>
        }
        <div>
          {placeholder || makeRichText(msg) || 'Sorry! We are having some trouble with retrieving the information for you. Please try the following:'}
        </div>
        <ul className={styles.actionList}>
          <li>
            {placeholder || makeSimpleText(reloadTemplate || '{0} the page', [retryLoading], true)}
          </li>
          <li>
            {placeholder || makeSimpleText(returnHomeTemplate || '{0} to homepage.', [homePageLink], true)}
          </li>
        </ul>
      </DangerAlert>
    </Container>
  );
};

export const preload = {
  texts: textKeys,
};

export default memo(ErrorBanner);

ErrorBanner.propTypes = {
  timeId: PropTypes.string,
};
