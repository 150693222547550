export type CreationResult = {
  contactId?: string | null;
  failureText?: string | null;
  isCreated?: boolean;
} | null;

export const CREATE_PROSPECT_PROCESSED = 'CREATE_PROSPECT_PROCESSED' as const;
export const createProspectProcessed = (result: CreationResult) => ({ type: CREATE_PROSPECT_PROCESSED, payload: result });

export type CreateProspectAction = ReturnType<
  | typeof createProspectProcessed
>;
