import PropTypes from 'prop-types';
import { Placeholder } from 'components/primitives/placeholders';
import { SimpleText } from 'components/sanaText';
import QuantityBox from './QuantityBox';

const OrderBox = ({
  product,
  className = '',
  canOrderProducts,
}) => {
  const {
    isOrderable,
  } = product;

  let content = null;
  const placeholder = <Placeholder className="placeholder" />;

  if (isOrderable == null) {
    content = placeholder;
  } else if (isOrderable === false) {
    content = (
      <>
        <span className="msg-not-available">
          <SimpleText textKey="Product_NotAvailable" placeholder={placeholder} />
        </span>
        <QuantityBox canOrderProducts={canOrderProducts} product={product} />
      </>
    );
  } else {
    content = <QuantityBox canOrderProducts={canOrderProducts} product={product} />;
  }

  return (
    <div className={className}>
      {content}
    </div>
  );
};

OrderBox.propTypes = {
  product: PropTypes.shape({
    isOrderable: PropTypes.bool,
  }),
  className: PropTypes.string,
  canOrderProducts: PropTypes.bool.isRequired,
};

export default OrderBox;
