import { useEffect, useState } from 'react';
import { useServices } from 'utils/services';
import PropTypes from 'prop-types';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { useSelector } from 'react-redux';

const VimeoThumbnail = ({ videoId, title }) => {
  const noImage = useSelector(({ settings }) => settings.product.noImage.small);
  const [thumbnailUrl, setThumbnailUrl] = useState();

  const onUrlReceived = url => {
    setThumbnailUrl(url);
  };

  useGetVimeoThumbnailUrl(videoId, noImage, onUrlReceived);

  if (!thumbnailUrl)
    return null;

  return <img src={thumbnailUrl} alt={title} />;
};

VimeoThumbnail.propTypes = {
  videoId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
export default VimeoThumbnail;

function useGetVimeoThumbnailUrl(videoId, noImage, onUrlReceived) {
  const vimeoApiUrl = `https://vimeo.com/api/v2/video/${videoId}.json`;

  const { api } = useServices();

  useEffect(() => {
    const fetch = api.fetch(vimeoApiUrl);

    const subscription = fetch.pipe(
      map(([videoInfo]) => videoInfo?.thumbnail_large),
      catchError(() => of(noImage)),
    ).subscribe(onUrlReceived);

    return () => subscription.unsubscribe();
  }, []);
}
