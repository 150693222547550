exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Modal_modal{height:100%;left:0;padding:10px 0;position:fixed;top:0;width:100%;z-index:9300000}.Modal_modal.Modal_fullscreen{padding:0}.Modal_modal.Modal_fullscreen .Modal_middle{width:100%}.Modal_modal.Modal_fullscreen .Modal_content{min-height:100vh;width:100%}.Modal_modal.Modal_hidden{display:none}.Modal_body{align-items:center;display:flex;justify-content:center}.Modal_body,.Modal_overlay{height:100%;width:100%}.Modal_overlay{background-color:var(--theme-popup_Overlay_BackgroundColor,#000);left:0;opacity:.2;position:fixed;top:0}.Modal_middle{background:var(--theme-popup_BackgroundColor,#fff);border:1px solid var(--theme-popup_BorderColor,#e6e6e6);border-radius:3px;max-height:100%;max-width:100%;position:relative}.Modal_middle:focus{outline:none}.Modal_middle:before{border-radius:3px;bottom:0;box-shadow:0 0 10px 0 var(--theme-popup_Overlay_BackgroundColor,#000);content:\"\";left:0;opacity:.5;pointer-events:none;position:absolute;right:0;top:0}.Modal_content{background:var(--theme-popup_BackgroundColor,#fff);border-radius:3px;max-height:calc(100vh - 20px);max-width:100%;overflow-y:auto;padding:24px 36px 24px 32px;position:relative;text-align:left;width:592px;z-index:1}.Modal_content.Modal_content-medium-width{width:1008px}.Modal_content.Modal_content-large-width{width:1030px}.Modal_close-btn-wrapper{position:absolute;right:12px;top:8px;z-index:2}.Modal_close-btn{background:none;border:none;cursor:pointer;height:24px;padding:0;width:24px}.Modal_close-btn img{max-height:24px;max-width:24px}.Modal_close-btn svg{fill:#2f2f33;height:24px;width:24px}.Modal_title{margin:0;padding:0 0 13px;padding-right:24px;text-transform:var(--theme-h2_TextTransform,uppercase)}@media (-ms-high-contrast:active),(-ms-high-contrast:none){.Modal_body{flex-direction:column}.Modal_middle{min-height:1px}}", ""]);

// exports
exports.locals = {
	"modal": "Modal_modal",
	"fullscreen": "Modal_fullscreen",
	"middle": "Modal_middle",
	"content": "Modal_content",
	"hidden": "Modal_hidden",
	"body": "Modal_body",
	"overlay": "Modal_overlay",
	"content-medium-width": "Modal_content-medium-width",
	"contentMediumWidth": "Modal_content-medium-width",
	"content-large-width": "Modal_content-large-width",
	"contentLargeWidth": "Modal_content-large-width",
	"close-btn-wrapper": "Modal_close-btn-wrapper",
	"closeBtnWrapper": "Modal_close-btn-wrapper",
	"close-btn": "Modal_close-btn",
	"closeBtn": "Modal_close-btn",
	"title": "Modal_title"
};