import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { mergeMap, map } from 'rxjs/operators';
import { paymentFailedQuery } from './queries';
import { routesBuilder } from 'routes';
import { navigateTo } from 'behavior/events';
import { loadSystemPageQuery, initSystemPageContent } from 'behavior/pages/system';

export default (routeData, _state$, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi(loadSystemPageQuery('paymentFailed')).pipe(
      map(({ pages: { paymentFailed } }) => ({
        page: {
          ...paymentFailed,
          component: PageComponentNames.PaymentFailed,
          transaction: { id: '' },
        },
      })),
      initSystemPageContent(),
    );
  }

  if (!transactionId)
    return of(null);

  return api.graphApi(paymentFailedQuery, { id: transactionId }).pipe(
    mergeMap(({ pages: { paymentFailed }, paymentTransaction: transaction }) => {
      if (transaction == null)
        return of(null);

      if (transaction.isPaymentError)
        return of({
          action$: of(navigateTo(routesBuilder.forPaymentError(transactionId))),
        });

      return of({
        page: {
          ...paymentFailed,
          component: PageComponentNames.PaymentFailed,
          transaction,
        },
      });
    }),
    initSystemPageContent(),
  );
};
