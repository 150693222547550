//149978 [Roland DG Australia] 3.1. Adjust the order type in GP based on the delivery date.
import { memo, useCallback, useState } from 'react';
import { DatePicker } from 'components/primitives/form';
import dateOnly from 'date-only';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { deliveryDateChangeRequest } from 'behavior/user';

const DeliveryDatePicker = () => {
  const { date } = useSelector(({ user, localization }) => ({
    date: user.deliveryDate,
  }), shallowEqual);

  const dispatch = useDispatch();
  const [dateValue, setDateValue] = useState(dateOnly.toISOString(new Date(date)));

  const handleDateChange = useCallback(
    date => {
      if (!date || isNaN(new Date(date).getTime()))
        return;
      setDateValue(date);
      dispatch(deliveryDateChangeRequest(date));
    },
    [dateValue],
  );

  return (
    <div>
      <DatePicker
        minDate={dateOnly.today()}
        value={dateValue}
        onChange={handleDateChange}
        autoFocus
      />
    </div>
  );
};

export default memo(DeliveryDatePicker);
