import type { CalculatedProductData, ProductData } from './types';

type SortingType = {
  field: string;
  ascending: boolean;
};

export const PRODUCT_SET_REQUESTED = 'PRODUCT_SET_REQUESTED' as const;
export const requestProductSet = (contentBlockId: string, productSetId: string, size: number, sorting: SortingType | undefined, calculate: boolean) => ({
  type: PRODUCT_SET_REQUESTED,
  payload: { contentBlockId, productSetId, size, sorting, calculate },
});

export const PRODUCT_SET_RECEIVED = 'PRODUCT_SET_RECEIVED' as const;
export const productSetReceived = (contentBlockId: string, products: Array<ProductData | CalculatedProductData>, calculated: boolean) => ({
  type: PRODUCT_SET_RECEIVED,
  payload: { contentBlockId, products, calculated },
});

export type ProductSetAction = ReturnType<
  | typeof requestProductSet
  | typeof productSetReceived
>;