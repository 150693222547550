exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ScrollDownIcon_button{background:transparent;border:none;bottom:10px;cursor:pointer;height:62px;left:50%;opacity:1;padding:0;position:absolute;transform:translateX(-50%);transition:opacity .5s ease,z-index .01s linear;width:26px;will-change:opacity,z-index;z-index:8200000}.ScrollDownIcon_hidden{opacity:0;transition-delay:0s,.5s;z-index:-1}.ScrollDownIcon_chevron{animation:ScrollDownIcon_move 3s ease-out infinite;animation-delay:3s;height:6px;opacity:0;position:absolute;top:0;transform:scale3d(.5,.5,.5);width:26px}.ScrollDownIcon_chevron:first-child{animation:ScrollDownIcon_move 3s ease-out 1s infinite}.ScrollDownIcon_chevron:nth-child(2){animation:ScrollDownIcon_move 3s ease-out 2s infinite}.ScrollDownIcon_chevron:after,.ScrollDownIcon_chevron:before{background:#fff;content:\"\";height:100%;position:absolute;top:0;width:51%}.ScrollDownIcon_chevron:before{box-shadow:-1px 0 0 1px rgba(0,0,0,.15);left:0;transform:skew(0deg,30deg)}.ScrollDownIcon_chevron:after{box-shadow:1px 0 0 1px rgba(0,0,0,.15);right:0;transform:skew(0deg,-30deg);width:50%}@keyframes ScrollDownIcon_move{25%{opacity:1}33%{opacity:1;transform:translateY(30px)}67%{opacity:1;transform:translateY(40px)}to{opacity:0;transform:translateY(55px) scale3d(.5,.5,.5)}}", ""]);

// exports
exports.locals = {
	"button": "ScrollDownIcon_button",
	"hidden": "ScrollDownIcon_hidden",
	"chevron": "ScrollDownIcon_chevron",
	"move": "ScrollDownIcon_move"
};