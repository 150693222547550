import { ReactNode, useCallback, useState, useMemo } from 'react';
import RegistryContext, { Register, Registry, ReadonlyRegistry } from './RegistryContext';

type RegistryProviderProps = { children?: ReactNode };

const RegistryProvider = ({ children }: RegistryProviderProps) => {
  const [registry, setRegistry] = useState<Registry>({});

  const register: Register = useCallback((area, id, payload) => {
    setRegistry(prevRegistry => ({
      ...prevRegistry,
      [area]: {
        ...prevRegistry[area],
        [id]: payload,
      },
    }));

    return () => setRegistry(prevRegistry => {
      if (!(area in prevRegistry))
        return prevRegistry;

      if (!(id in prevRegistry[area]))
        return prevRegistry;

      const newAreaRegistry = omit(prevRegistry[area], id);
      return Object.keys(newAreaRegistry).length
        ? { ...prevRegistry, [area]: newAreaRegistry }
        : omit(prevRegistry, area);
    });
  }, []);

  const contextValue = useMemo(() => ({
    registry: registry as ReadonlyRegistry,
    register,
    getFirst<TPayload>(area: string): Readonly<TPayload> | undefined {
      return registry[area] && Object.values(registry[area])[0] as Readonly<TPayload> | undefined;
    },
  }), [registry, register]);

  return (
    <RegistryContext.Provider value={contextValue}>
      {children}
    </RegistryContext.Provider>
  );
};

export default RegistryProvider;

function omit<T extends Record<string, unknown>, D extends keyof T>(obj: T, key: D) {
  const newObj = { ...obj };
  delete newObj[key];

  return newObj;
}
