import type { Handler } from '../types';
import type { EntityTemplateField } from 'behavior/entityTemplates';
import type { BackTo } from 'routes/types';
import { loadCreateProspectPageQuery } from './queries';
import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { initPageContent, SystemPage, SystemPageData } from 'behavior/pages/system';
import { getBackTo } from '../helpers';
import { RouteName } from 'routes';

type ProspectRouteData = {
  routeName: RouteName.CreateProspect;
  params?: {
    language: number;
  };
};

type ProspectPageResponse = {
  pages: {
    createProspect: SystemPageData;
  };
  profile: {
    prospectFields: EntityTemplateField[];
  };
};

type ProspectPage = SystemPage & {
  component: PageComponentNames.CreateProspect;
  templateFields: EntityTemplateField[];
  backTo?: BackTo;
};

const handler: Handler<ProspectRouteData, ProspectPage> = (routeData, state$, { api }) => api.graphApi<ProspectPageResponse>(loadCreateProspectPageQuery).pipe(
  map(({ pages, profile }) => {
    const page = pages.createProspect;
    if (!page)
      return null;

    const result = {
      page: initPageContent(page) as ProspectPage,
    };
    result.page.templateFields = profile.prospectFields;
    result.page.component = PageComponentNames.CreateProspect;
    result.page.backTo = getBackTo(state$, [RouteName.CreateProspect], routeData.params?.language);

    return result;
  }),
);

export default handler;
