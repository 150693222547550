exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Logo_logo a{display:block;line-height:0}.Logo_logo img{display:inline-block;max-width:100%;vertical-align:bottom}", ""]);

// exports
exports.locals = {
	"logo": "Logo_logo"
};