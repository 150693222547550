import type { Handler } from '../types';
import type { RouteData } from 'routes';
import type { RowContentElementData } from 'behavior/content';
import type { Api } from 'utils/api';
import type { StoreDependencies } from 'behavior/types';
import type { AppState } from 'behavior';
import type { WishListLine } from './types';
import type { SystemPage, SystemPageData } from 'behavior/pages/system';
import { map, first, switchMap, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { loadPageQuery } from './queries';
import { initComponent } from 'behavior/pages/helpers';
import { PageComponentNames } from '../componentNames';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { initSystemPageContent, loadSystemPageQuery } from 'behavior/pages/system';
import { RouteName } from 'routes';
import { StateObservable } from 'redux-observable';
import { NonOrderableReason } from 'behavior/basket';
import { areAnalycticsSettingsLoaded } from 'behavior/analytics';

const handler: Handler<WishListPageRouteData, WishListPage> = ({ params }, state$, { api }) => {
  if (params?.previewToken) {
    return api.graphApi<SystemPageQueryResponse>(loadSystemPageQuery('wishList')).pipe(
      map(({ pages: { wishList } }) => {
        if (!wishList)
          return null;

        const page = wishList as WishListPageData;

        page.component = PageComponentNames.WishList;
        page.productLines = Array.from(Array(3)).map((_, index) => ({
          id: index.toString(),
          isOrderable: true,
          product: { id: (index + 1).toString() },
        }));

        return { page };
      }),
      initSystemPageContent(),
    );
  }

  return state$.pipe(
    first(areAnalycticsSettingsLoaded),
    switchMap(({ settings }) => settings.wishListEnabled
      ? loadPage(api, state$)
      : of(null),
    ),
  );
};

export default handler;

function loadPage(api: Api, state$: StateObservable<AppState>) {
  return requestAbility(AbilityTo.ViewPrices, state$, { api } as StoreDependencies).pipe(
    mergeMap(abilityState => api.graphApi<WishListPageQueryResponse>(loadPageQuery(isTrackingEnabled(state$)), {
      calculated: abilityState !== AbilityState.NotAvailable,
    }).pipe(
      map(result => mapResult(result)),
      initComponent(PageComponentNames.WishList),
      initSystemPageContent(),
    )),
  );
}

function mapResult(
  {
    pages: {
      wishList: page,
    },
    wishList,
  } : WishListPageInfo,
) {

  if (!page)
    return null;

  return {
    ...page,
    ...wishList,
  };
}

function isTrackingEnabled(state$: StateObservable<AppState>) {
  return state$.value.analytics!.isTrackingEnabled;
}

type WishListPageRouteData = RouteData & {
  routeName: RouteName.WishList;
  params: {
    previewToken?: string;
  };
};

type WishListPage = SystemPage & {
  component: PageComponentNames.WishList;
};

type SystemPageQueryResponse = {
  pages: {
    wishList: {
      metaTitle: string | null;
      content: {
        header: RowContentElementData[] | null;
        footer: RowContentElementData[] | null;
      } | null;
    } | null;
  };
};

type WishListPageInfo = {
  pages: {
    wishList: {
      metaTitle: string | null;
      content: {
        header: RowContentElementData[] | null;
        footer: RowContentElementData[] | null;
      } | null;
    } | null;
  };
  wishList: {
    productLines: Array<WishListLine>;
    nonOrderableLines: Array<{
      description: string | null;
      reason: NonOrderableReason | null;
    }> | null;
  } | null;
};

type WishListPageQueryResponse = {
  pages: {
    wishList: {
      metaTitle: string | null;
      content: {
        header: RowContentElementData[] | null;
        footer: RowContentElementData[] | null;
      } | null;
    } | null;
  };
  wishList: {
    productLines: Array<{
      id: string | null;
      price: number | null;
      title: string | null;
      uom: {
        id: string;
        description: string | null;
      } | null;
      product: {
        id: string;
        title: string | null;
        image: {
          small: string | null;
        } | null;
        images: Array<{
          small: string | null;
          variantId: string | null;
        }> | null;
        url: string | null;
        isOrderable: boolean | null;
        uom: {
          id: string;
        } | null;
        variants: Array<{
          id: string;
          isOrderable: boolean | null;
        }> | null;
        categoriesPaths?: Array<{
          categories: Array<{
            name: string;
          }>;
        }>;
      };
      subLines: Array<{
        id: string | null;
        price: number | null;
        title: string | null;
        uom: {
          id: string;
          description: string | null;
        } | null;
        variationId: string;
        configurationInfo: {
          products: Array<{
            image: {
              small: string | null;
            } | null;
          }> | null;
        } | null;
        configuration?: {
          masterProduct: {
            id: string;
            title: string | null;
            categoriesPaths: Array<{
              categories: Array<{
                name: string;
              }>;
            }>;
          };
          products: Array<{
            id: string;
            variantId: string | null;
          }> | null;
        } | null;
      }> | null;
      configurationInfo: {
        products: Array<{
          image: {
            small: string | null;
          } | null;
        }> | null;
      } | null;
      configuration: {
        masterProduct: {
          id: string;
          title: string | null;
          categoriesPaths: Array<{
            categories: Array<{
              name: string;
            }>;
          }>;
        };
        products: Array<{
          id: string;
          variantId: string | null;
        }> | null;
      } | null;
    }>;
    nonOrderableLines: Array<{
      description: string | null;
      reason: NonOrderableReason | null;
    }> | null;
  } | null;
};

type WishListPageData = SystemPageData & {
  component: PageComponentNames.WishList;
  productLines: Array<{
    id: string;
    isOrderable: boolean;
    product: {
      id: string;
    };
  }>;
};