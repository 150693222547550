import styles from './Modal.module.scss';
import { useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useFocusLockWhiteList } from 'utils/hooks';
import FocusLock from 'react-focus-lock';
import ReactResizeDetector from 'react-resize-detector';
import { getTargetScrollBarWidth } from 'scroll-lock';
import CloseButton from './CloseButton';
import { useBodyScrollToggle } from './hooks';

const sizeClassNames = {
  medium: styles.contentMediumWidth,
  large: styles.contentLargeWidth,
};

const Modal = ({
  opened,
  children,
  hide,
  fullscreen,
  hideCloseButton,
  className = '',
  containerId,
  size,
  closeBtnTitle,
}) => {
  if (typeof document === 'undefined')
    return null;

  const modalContainer = containerId
    ? document.getElementById(containerId)
    : document.getElementById('modals');

  if (!modalContainer)
    return null;

  const modalRef = useRef(null);
  const contentRef = useRef();
  const closeBtnWrapperRef = useRef();
  const whiteList = useFocusLockWhiteList();

  useBodyScrollToggle(opened);

  const onEscapeKey = e => {
    if (e.key === 'Escape') {
      hide();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
  };

  const handleContentResize = useCallback(() => {
    if (!closeBtnWrapperRef.current)
      return;

    const scrollWidth = getTargetScrollBarWidth(contentRef.current, true);
    if (scrollWidth)
      closeBtnWrapperRef.current.style.marginRight = scrollWidth + 'px';
    else {
      closeBtnWrapperRef.current.style.marginRight = '';
    }
  }, []);

  const modalClassName = `${styles.modal} ${fullscreen ? styles.fullscreen : ''} ${opened ? '' : styles.hidden} ${className}`;
  const contentSizeClassName = sizeClassNames[size] || '';

  const modalBody = (
    <FocusLock
      disabled={!opened}
      shards={[
        modalRef.current,
        document.querySelector('.react-toast-notifications__container'),
      ]}
      returnFocus
      whiteList={whiteList}
    >
      <div
        className={modalClassName}
        ref={modalRef}
        role="dialog"
        aria-modal="true"
      >
        <div className={styles.body} onKeyDown={onEscapeKey} role="presentation">
          {/*eslint-disable-next-line jsx-a11y/click-events-have-key-events*//*keyDown is listened on wrapper*/}
          <div className={styles.overlay} onClick={hide} role="presentation" />
          <div className={styles.middle} tabIndex="-1">
            {!hideCloseButton && <CloseButton hide={hide} closeBtnTitle={closeBtnTitle} ref={closeBtnWrapperRef} />}
            {/* data-scroll-lock-scrollable - attribute that allows to scroll modal on touch devices */}
            <div className={`${styles.content} ${contentSizeClassName}`} ref={contentRef} data-scroll-lock-scrollable>
              {children}
              <ReactResizeDetector handleHeight onResize={handleContentResize} />
            </div>
          </div>
        </div>
      </div>
    </FocusLock>
  );

  return ReactDOM.createPortal(modalBody, modalContainer);
};

Modal.propTypes = {
  opened: PropTypes.bool,
  children: PropTypes.node.isRequired,
  hide: PropTypes.func.isRequired,
  fullscreen: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  className: PropTypes.string,
  containerId: PropTypes.string,
  closeBtnTitle: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizeClassNames)),
};

export default Modal;
