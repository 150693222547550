import type { Handler } from '../types';
import type { SystemPage, SystemPageData } from '../system';
import { PageComponentNames } from 'behavior/pages';
import { RouteData, RouteName } from 'routes';
import { decapitalize } from 'utils/helpers';
import { loadSystemPageQuery } from '../system/queries';
import { getBackTo } from '../helpers';
import { initPageContent } from '../system';
import { of } from 'rxjs';
import { tap, switchMap, pluck, first, map } from 'rxjs/operators';
import { StoreType, areSettingsLoaded } from 'behavior/settings';

type PageRouteData = RouteData & {
  routeName: RouteName.ForgotPassword;
};

type ForgotPasswordPage = SystemPage & { component: PageComponentNames.ForgotPassword };
type ClosedForgotPasswordPage = { emptyLayout: true; component: PageComponentNames.ClosedStoreForgotPassword };

type Page = (ForgotPasswordPage | ClosedForgotPasswordPage)
  & Pick<RouteData, 'options'>
  & { backTo: ReturnType<typeof getBackTo> };

type PageResponse = {
  pages: {
    forgotPassword: SystemPageData;
  };
};

type PageHandler = Handler<PageRouteData, Page>;
type State = Parameters<PageHandler>[1];
type Api = Parameters<PageHandler>[2]['api'];

const forgotPasswordPageName = decapitalize(RouteName.ForgotPassword);

const handler: PageHandler = (routeData, state$, { api }) => {
  return state$.pipe(
    pluck('settings'),
    first(areSettingsLoaded),
    switchMap(
      settings => settings.storeType === StoreType.Closed
        ? loadClosedStoreForgotPasswordPage(routeData, state$)
        : loadSystemForgotPasswordPage(routeData, state$, api),
    ),
    tap<{ page: Page }>(({ page }) => {
      if (routeData.options)
        page.options = routeData.options;
    }),
  );
};

export default handler;

function loadClosedStoreForgotPasswordPage(routeData: PageRouteData, state$: State) {
  return of({
    page: {
      component: PageComponentNames.ClosedStoreForgotPassword as const,
      emptyLayout: true as const,
      backTo: _getBackTo(state$, routeData),
    },
  });
}

function loadSystemForgotPasswordPage(routeData: PageRouteData, state$: State, api: Api) {
  return api.graphApi<PageResponse>(loadSystemPageQuery(forgotPasswordPageName)).pipe(
    pluck('pages', 'forgotPassword'),
    map(page => ({
      page: {
        ...initPageContent(page),
        component: PageComponentNames.ForgotPassword as const,
        backTo: _getBackTo(state$, routeData),
      },
    })),
  );
}

function _getBackTo(state$: State, routeData: PageRouteData) {
  return getBackTo(state$, [
    RouteName.Login,
    RouteName.ForgotPassword,
  ], routeData.params && routeData.params.language);
}
