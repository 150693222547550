import ConnectionOffline_TemporaryNotAvailable from './TemporaryNotAvailable';
import ConnectionOffline_Text from './Offline';
import Login_Footer from './LoginFooter';
import TooltipSign from './TooltipSign';
import BasketPage_B2B_ColumnFooter from './BasketPage_B2B_ColumnFooter';
import PaymentMethodsNotAvailable from './PaymentMethodsNotAvailable';

export default {
  Login_Footer,
  ConnectionOffline_TemporaryNotAvailable,
  ConnectionOffline_Text,
  TooltipSign,
  BasketPage_B2B_ColumnFooter,
  PaymentMethodsNotAvailable,
};
