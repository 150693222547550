import { EMPTY, Observable } from 'rxjs';
import { useState } from 'react';
import { useEventObservable } from 'utils/rxjs';

export const useShouldLoad = (emitter: Observable<number> | undefined, index: number, active: boolean) => {
  const [shouldLoad, setShouldLoad] = useState(active);

  useEventObservable(emitter || EMPTY, nextIndex => {
    if (nextIndex === index)
      setShouldLoad(true);
  }, !!emitter && !shouldLoad);

  return shouldLoad;
};
