import { useInsiteEditorTexts } from 'components/visualDesigner/insiteEditor';
import { useSelector } from 'react-redux';

const useEditableSpecifications = specFields => {
  if (!useSelector(state => state.insiteEditor.initialized))
    return specFields;

  return useInsiteEditorTexts(specFields, (field, getText) => {
    return { 
      ...field,
      name: getText(field.titleTextKey, field.name),
      value: getText(field.valueTextKey, field.value),
    };
  }, { textOnly: true, isConstructedTexts: true });
};

export default useEditableSpecifications;
