import type { Handler } from '../types';
import type { SystemPage, SystemPageData } from '../system';
import type { BackTo } from 'routes/types';
import type { OrderAuthorizationStatus } from '../document';
import type { RouteName } from 'routes';
import type { RowContentElementData } from 'behavior/content';
import type { OrderAuthorizations } from './types';
import type { Filter } from '../documents/types';
import { initSystemPageContent } from '../system';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { requestAuthorizations } from './actions';
import { orderAuthorizationsPageQuery } from './queries';
import { createOptions, normalizeFilter } from '../documents';
import { loadSystemPageQuery } from '../system/queries';

interface OrderAuthorizationsFilter extends Filter {
  authorizationStatus: OrderAuthorizationStatus | null;
}

type NotFoundPage = {
  component: PageComponentNames.NotFound;
};

type OrderAuthorizationsPage = SystemPage & {
  component: PageComponentNames;
  size: number;
  filter: OrderAuthorizationsFilter;
  orderAuthorizations?: {
    totalCount: number;
  };
  backTo?: BackTo;
};

type OrderAuthorizationsRouterData = SystemPageData & {
  routeName: RouteName.OrderAuthorizations;
  params?: {
    filter: OrderAuthorizationsFilter;
    index?: number;
    previewToken?: string;
  };
  options?: {
    onlyItems?: boolean;
    backTo?: BackTo;
  };
};

type SystemPageResponse = {
  pages: {
    orderAuthorizations: {
      metaTitle: string | null;
      content: {
        header: RowContentElementData[] | null;
        footer: RowContentElementData[] | null;
      } | null;
    } | null;
  };
};

type OrderAuthorizationsPageResponse = SystemPageResponse & {
  profile: {
    orderAuthorizations: OrderAuthorizations;
  };
};

export const size = 10;

const handler: Handler<OrderAuthorizationsRouterData, OrderAuthorizationsPage | NotFoundPage> = ({ params, options: pageOptions }, state$, { api }) => {
  const filter = normalizeFilter(params && params.filter);

  if (!filter.authorizationStatus)
    filter.authorizationStatus = null;

  if (params?.previewToken) {
    return api.graphApi<SystemPageResponse>(loadSystemPageQuery('orderAuthorizations')).pipe(
      map(({ pages: { orderAuthorizations: page } }) => {
        if (!page)
          return null;

        const authorizationsPage = {
          ...page,
          component: PageComponentNames.OrderAuthorizations,
          orderAuthorizations: { totalCount: 0 },
          size,
          filter,
        };

        return { page: authorizationsPage };
      }),
      initSystemPageContent(),
    );
  }

  const onlyItems = pageOptions && pageOptions.onlyItems;
  const backTo = pageOptions && pageOptions.backTo;
  const input = createOptions(params, filter, onlyItems);

  if (onlyItems)
    return of({
      action$: of(requestAuthorizations(input)),
      page: {
        ...state$.value.page,
        filter,
      } as OrderAuthorizationsPage,
    });

  return api.graphApi<OrderAuthorizationsPageResponse>(orderAuthorizationsPageQuery, { input }).pipe(
    map(({ pages: { orderAuthorizations: page }, profile: { orderAuthorizations } }) => {
      if (!page)
        return null;

      const authorizationsPage = {
        ...page,
        component: PageComponentNames.OrderAuthorizations,
        orderAuthorizations,
        size,
        filter,
        backTo,
      };

      return { page: authorizationsPage };
    }),
    initSystemPageContent(),
  );
};

export default handler;
