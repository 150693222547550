import loadable from '@loadable/component';
import { Media } from 'components/responsive';

const DesktopDetailsTemplate = loadable(() => import(/*webpackChunkName:"pdp"*/'./DesktopDetailsTemplate'));
const MobileDetailsTemplate = loadable(() => import(/*webpackChunkName:"pdp"*/'./MobileDetailsTemplate'));

const DetailsPage = () => (
  <>
    <Media at="xs">
      <MobileDetailsTemplate />
    </Media>
    <Media greaterThanOrEqual="sm">
      <DesktopDetailsTemplate />
    </Media>
  </>
);

export default DetailsPage;
