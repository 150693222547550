import {
  PRODUCTLIST_PRODUCTS_UPDATED,
  PRODUCTLIST_PRODUCTS_GENERALINFO_LOADED,
  PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED,
} from './actions';
import { VIEWER_CHANGED } from 'behavior/events';
import { createReducer } from 'utils/redux';
import { PageComponentNames } from '../componentNames';
import { sortOptionsAreEqual } from './helpers';
import { deleteProductCalculatedInfo } from 'behavior/products/product';

export default createReducer(null, {
  [PRODUCTLIST_PRODUCTS_UPDATED]: calculatedFieldsLoaded,
  [PRODUCTLIST_PRODUCTS_GENERALINFO_LOADED]: productsGeneralInfoLoaded,
  [PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED]: onProductsRequested,
  [VIEWER_CHANGED]: onViewerChanged,
});

function calculatedFieldsLoaded(state, action) {
  if (!state.products)
    return state;

  const calculatedProducts = action.payload;
  const updatedProducts = state.products.map(product => {
    const calculatedInfo = calculatedProducts.find(p => p.id === product.id);
    const calculatedInfoChanged = typeof product.price === 'undefined';
    if (!calculatedInfo)
      return { ...product, calculatedInfoChanged };
    return { ...product, ...calculatedInfo, calculated: true, calculatedInfoChanged };
  });

  return {
    ...state,
    products: updatedProducts,
  };
}

function productsGeneralInfoLoaded(state, action) {
  if (!productListComponentNames.has(state.component))
    return state;

  const {
    products,
    appendProducts,
    pageSize,
    totalCount,
    sorting,
  } = action.payload;

  const result = updatePageProducts(state, products, appendProducts, pageSize);

  if (totalCount)
    result.totalCount = totalCount;

  if (sorting && !sortOptionsAreEqual(sorting, result.selectedSorting))
    result.selectedSorting = sorting;

  return result;
}

function updatePageProducts(page, products, appendProducts, pageSize) {
  const normalizedProductsCount = pageSize - page.products.length % pageSize;
  const updatedProducts = appendProducts
    ? [...page.products, ...products.slice(-normalizedProductsCount)]
    : products;

  return {
    ...page,
    products: AddAppendedProp(updatedProducts, page.products),
  };
}

function AddAppendedProp(updatedProducts, products) {
  return updatedProducts.map(p => ({ ...p, appended: !products.some(product => product.id === p.id) }));
}

function onProductsRequested(state, action) {
  const { options: { sorting } } = action.payload;
  if (!sorting)
    return state;

  const [sort] = sorting;
  if (sortOptionsAreEqual(sort, state.selectedSorting))
    return state;

  return { ...state, selectedSorting: sort };
}

const productListComponentNames = new Set([
  PageComponentNames.ProductList,
  PageComponentNames.Search,
  PageComponentNames.ProductsWithCategory,
]);

function onViewerChanged(state, _action) {
  if (!state.products || !productListComponentNames.has(state.component))
    return state;

  const products = state.products.map(product => {
    const updatedProduct = deleteProductCalculatedInfo(product);

    delete updatedProduct.appended;
    delete updatedProduct.calculatedInfoChanged;

    return updatedProduct;
  });

  return { ...state, products };
}