import { useCallback, useState } from 'react';

export const useVimeoNotFound = (onLoad: () => void) => {
  const [vimeoNotFound, setVimeoNotFound] = useState(false);

  const onVimeoError = useCallback((e: Error) => {
    onLoad();
    if (e.message.includes('not found'))
      setVimeoNotFound(true);
  }, []);

  return { vimeoNotFound, onVimeoError, setVimeoNotFound };
};
