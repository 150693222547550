//149978 [Roland DG Australia] 3.1. Adjust the order type in GP based on the delivery date.
import { Modal, useModal } from 'components/objects/modals';
import DeliveryDatePopup from './deliveryDatePopup';
import { useOnLocationChanged } from 'utils/hooks';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { connect } from 'react-redux';
import { getToLocaleDate } from 'utils/format';

const DeliveryDateBlock = ({ model, isDesignerMode, culture }) => {

  const { user } = useSelector(({ user }) => ({
    user,
  }), shallowEqual);

  const formatDateUTC = getToLocaleDate(culture);

  const {
    bannerFontColor,
    bannerColor,
  } = model;

  const { deliveryDate } = user;
  const { opened, show, hide } = useModal();
  const closePopup = () => {
    hide();
  };
  useOnLocationChanged(hide);

  const onClick = e => {
    e.preventDefault();
    show();
  };

  if (user.customer === null)
    return null;

  if (isDesignerMode)
    return (
      <>
        <span><b>{'Your current set delivery date is 04/04/2021'}<button>Click here</button>{'to change the delivery date for the current order.'}</b></span>
      </>
    );

  return (
    <div className="orderbanner_edit" style={{ backgroundColor: bannerColor, color: bannerFontColor }}>
      <span>
        <b>
          <SimpleText textKey="OrderType_CurrentDeliveryDate" formatWith={[formatDateUTC(deliveryDate)]} />
          <button onClick={onClick}><SimpleText textKey="OrderType_ClickHere" /></button><SimpleText textKey="OrderType_CurrentDeliveryDateChange" />
        </b>
      </span>
      <Modal opened={opened} hide={closePopup} size="large" resetContentOnClose className="stock_availbility-popup">
        <DeliveryDatePopup />
      </Modal>
    </div>
  );
};

DeliveryDateBlock.propTypes = {
  model: PropTypes.shape({
    bannerColor: PropTypes.string,
    bannerFontColor: PropTypes.string,
  }),
  isDesignerMode: PropTypes.bool,
  culture: PropTypes.string,
};

const mapStateToProps = ({
  localization: { currentLanguage },
}) => ({
  culture: currentLanguage && currentLanguage.cultureName,
});

export default connect(mapStateToProps)(DeliveryDateBlock);