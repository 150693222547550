import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DomElement } from 'components/primitives/dom';
import { useIsVisualDesigner } from 'utils/hooks';

const Injection = ({ htmlInjections }) => {
  const isVisualDesigner = useIsVisualDesigner();
  if (isVisualDesigner || !htmlInjections?.bodyEnd)
    return null;

  return <DomElement content={htmlInjections.bodyEnd} />;
};

Injection.propTypes = {
  htmlInjections: PropTypes.shape({
    bodyEnd: PropTypes.string,
  }),
};

export default connect(({ settings: { htmlInjections } }) => ({ htmlInjections }))(Injection);