exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "#header .dropdown{color:var(--theme-header_Color,#333)}#header .dropdown.dropdown-opened{background-color:var(--theme-header_Dropdown_BackgroundColor,#fff);border-color:var(--theme-header_BorderColor,#e6e6e6) var(--theme-header_BorderColor,#e6e6e6) transparent}#header .dropdown.dropdown-opened.dropdown-offset-bottom{border-color:transparent var(--theme-header_BorderColor,#e6e6e6) var(--theme-header_BorderColor,#e6e6e6)}#header .dropdown .dropdown-body{border-color:var(--theme-header_BorderColor,#e6e6e6)}.Header_desktop,.Header_mobile{z-index:2}.Header_desktop{transform:translateZ(0)}html.pointer-mouse .Header_desktop:hover{z-index:8800000}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (pointer:coarse){html:not(.pointer-mouse) .Header_desktop:active{z-index:8800000}}}html:not(.pointer-mouse) .Header_nav-is-hovered{z-index:8800000}.Header_logo img{max-height:32px}@media print,screen{html.print .Header_logo{max-width:40%}html.print .Header_logo img{max-height:none}}", ""]);

// exports
exports.locals = {
	"desktop": "Header_desktop",
	"mobile": "Header_mobile",
	"nav-is-hovered": "Header_nav-is-hovered",
	"navIsHovered": "Header_nav-is-hovered",
	"logo": "Header_logo"
};