import styles from './LargeGallery.module.scss';
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/primitives/spinner/Spinner';
import { useShouldLoad } from './hooks';

function ImageSlide({ item, index, active, emitter }) {
  const [loaded, setLoaded] = useState(false);
  const shouldLoad = useShouldLoad(emitter, index, active);

  const onLoad = useCallback(() => setLoaded(true), []);

  return (
    <>
      {shouldLoad && (
        <img
          className={styles.slide}
          src={item.large}
          alt={item.title}
          title={item.title}
          onLoad={onLoad}
          draggable="false"
          onDragStart={preventDragEvent}
        />
      )}
      {!loaded &&
        <div className={`${styles.spinner} ${styles.slide}`}>
          <Spinner />
        </div>
      }
    </>);
}

ImageSlide.propTypes = {
  item: PropTypes.shape({
    large: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  emitter: PropTypes.object,
};

export default ImageSlide;

function preventDragEvent(e) {
  e.preventDefault();
}
