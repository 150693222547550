import { connect } from 'react-redux';
import { useSanaTexts } from 'components/sanaText';
import { preload as banner } from './ErrorBanner';
import { preload as retryToast } from './ErrorToastWithRetry';
import { preload as reloadToast } from './ErrorToastWithReload';
import { preload as errorBoundary } from './ErrorBoundary';

type Props = {
  shouldPreload: boolean;
};

const Preload = ({ shouldPreload }: Props) => {
  if (!shouldPreload)
    return null;

  useSanaTexts(banner.texts.concat(retryToast.texts, reloadToast.texts, errorBoundary.texts));

  return null;
};

export default connect(({ localization: { currentLanguage: { id } } }) => ({ shouldPreload: !!id }))(Preload);
