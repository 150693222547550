import { filterExistingImages, iEquals } from 'utils/helpers';
import { toUrlHash } from 'utils/url';
import { Steps, ShippingAddressOption } from './constants';
import { selectAddress, checkoutInfoUpdated } from './actions';
import { getRefreshCheckoutQuery } from './queries';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import { of, EMPTY } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { DocumentType } from 'behavior/documents';

export function filterImages(checkoutInfo) {
  if (checkoutInfo && checkoutInfo.productLines && checkoutInfo.productLines.list)
    for (const line of checkoutInfo.productLines.list)
      if (line.product && line.product.images)
        line.product.images = filterExistingImages(line.product.images);

  return checkoutInfo;
}

const stepsNames = Object.values(Steps);
export const getStepByUrlHash = hash => {
  if (hash && (hash = hash.substr(1)))
    return stepsNames.find(stepName => iEquals(stepName, hash));
};

export function updateShippingAddressIfNeeded(checkoutInfo, state$, dependencies) {
  let shippingAddress = checkoutInfo.shippingAddress;
  if (shippingAddress && (shippingAddress.shippingOption || !shippingAddress.address))
    return of(undefined);

  if (!shippingAddress)
    checkoutInfo.shippingAddress = shippingAddress = {};

  if (!checkoutInfo.billingAddress)
    return of(shipToExisting(shippingAddress, checkoutInfo.shippingAddresses));

  return requestAbility(AbilityTo.ShipToBillingAddress, state$, dependencies).pipe(
    map(shipToBilling => {
      if (shipToBilling === AbilityState.Available) {
        shippingAddress.address = checkoutInfo.billingAddress;
        shippingAddress.shippingOption = ShippingAddressOption.Billing;
        return selectAddress();
      }

      return shipToExisting(shippingAddress, checkoutInfo.shippingAddresses);
    }),
  );
}

function shipToExisting(shippingAddress, shippingAddresses) {
  if (!shippingAddresses?.length)
    return;

  shippingAddress.address = shippingAddresses[0];
  shippingAddress.shippingOption = ShippingAddressOption.Existing;
  return selectAddress(shippingAddress.address.id);
}

export function refreshCheckoutData(state$, deps) {
  const state = state$.value,
    isGuest = state.page.info.isGuest;

  return deps.api.graphApi(getRefreshCheckoutQuery(isGuest, !!state.page.info.quote), {
    asQuote: state.page.info?.isQuote || false,
    maxLines: state.settings.checkout.maxOverviewLines + 1,
  }).pipe(
    mergeMap(({ checkout, viewer }) => {
      if (!checkout)
        return EMPTY;

      if (!checkout.valid)
        return of(navigateOnIncorrect(state.page.info));

      adjustPaymentMethodData(checkout);
      adjustShippingMethodData(checkout);
      adjustGuestProfileData(checkout);

      if (isGuest)
        return of(checkoutInfoUpdated(checkout));

      adjustCheckoutAddresses(checkout, viewer);
      return updateShippingAddressIfNeeded(checkout, state$, deps).pipe(
        mergeMap(updateAddress => {
          if (updateAddress)
            return of(checkoutInfoUpdated(checkout), updateAddress);

          return of(checkoutInfoUpdated(checkout));
        }),
      );
    }),
  );
}

export function adjustShippingMethodData(info) {
  info.shippingMethodId = info.shippingMethod?.info?.id;
  info.recheckShippingMethod = info.shippingMethod?.unavailable;
 
  setShippingMethodTBD(info);
  delete info.shippingMethod;
}

export function adjustPaymentMethodData(info) {
  info.paymentMethodId = info.paymentMethod?.info?.id;
  info.recheckPaymentMethod = !info.paymentMethodId && !!info.paymentMethod;

  info.extraPaymentStep = info.paymentMethod?.extraPaymentCheckoutStep;
  info.customerDataStep = info.paymentMethod?.additionalCustomerDataStep;

  delete info.paymentMethod;
}

function setShippingMethodTBD(info) {
  const shippingMethod = info.shippingMethods?.find(function (method) {
    return method.id === info.shippingMethodId;
  });

  if (shippingMethod)
    info.shippingMethodTbdEnabled = shippingMethod.shippingCostTBDEnabled;
}

export function adjustGuestProfileData(info) {
  if (info.guestProfile) {
    info.billingAddress = info.guestProfile.billingAddress;
    info.email = info.guestProfile.email;
  }
  delete info.guestProfile;
}

export function adjustCheckoutAddresses(checkoutInfo, viewer) {
  const { customer: { billingAddress, shippingAddresses } } = viewer;
  checkoutInfo.billingAddress = billingAddress;
  checkoutInfo.shippingAddresses = shippingAddresses;
}

export function navigateOnIncorrect(checkoutInfo) {
  if (checkoutInfo && checkoutInfo.quote)
    return navigateTo(routesBuilder.forDocument(checkoutInfo.quote.id, DocumentType.Quote), checkoutInfo.quote.url);

  return navigateTo(routesBuilder.forBasket());
}

export function getInvalidStepNavigationInfo(steps, currentStep, location, isPromotion, asQuote, isGuest) {
  const invalidStepId = getInvalidStepId(steps, currentStep);
  if (!invalidStepId)
    return;

  const url = getInvalidStepUrl(invalidStepId, location);
  const to = getInvalidStepRouteData(invalidStepId, isPromotion, asQuote, isGuest);
  return { url, to };
}

function getInvalidStepId(steps, currentStep) {
  for (const step of steps) {
    if (step.id === currentStep)
      return;

    if (!step.isCompleted)
      return step.id;
  }

  return;
}

function getInvalidStepUrl(invalidStepId, location) {
  const hash = invalidStepId !== Steps.Address ? toUrlHash(invalidStepId) : '';
  return location.pathname + location.search + hash;
}

function getInvalidStepRouteData(invalidStepId, isPromotion, asQuote, isGuest) {
  const routeData = isPromotion
    ? routesBuilder.forQuotePromotion(invalidStepId)
    : routesBuilder.forCheckout(asQuote, invalidStepId, isGuest);
  routeData.options.stepInvalid = true;

  return routeData;
}
