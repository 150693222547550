import { ARIA_STATUS_SET } from './actions';
import type { AriaStatusAction } from './actions';

export type AriaStatusState = { text?: string } | { key?: string };

const initialState: AriaStatusState = {};

export default function ariaReducer(state = initialState, action: AriaStatusAction): AriaStatusState {
  switch (action.type) {
    case ARIA_STATUS_SET:
      return action.payload;
    default:
      return state;
  }
}
