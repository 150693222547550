import loadable from '@loadable/component';

const OrderAuthorizations = loadable(() => import(/*webpackChunkName:"sub-acc"*/'./Page'));

export default function orderAuthorizationsRenderer(page) {
  return (
    <OrderAuthorizations
      authorizations={page.orderAuthorizations?.items}
      totalCount={page.orderAuthorizations?.totalCount}
      size={page.size}
      filter={page.filter}
    />
  );
}
