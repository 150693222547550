export const enum OrderAuthorizationStatus {
  NotNeeded = 'NOT_NEEDED',
  Pending = 'PENDING',
  PendingNotSynced = 'PENDING_NOT_SYNCED',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
}

export const enum OrderAuthorizationAction {
  Approve = 'approve',
  Decline = 'decline',
  Cancel = 'cancel',
}

export const enum OrderAuthorizationActionResultStatus {
  Applied = 'APPLIED',
  AlreadyApplied = 'ALREADY_APPLIED',
}

export const enum OrderAuthorizationActionResultDetailedStatus {
  Approved = 'APPROVED',
  AlreadyApproved = 'ALREADY_APPROVED',
  Declined = 'DECLINED',
  AlreadyDeclined = 'ALREADY_DECLINED',
  Cancelled = 'CANCELLED',
}
