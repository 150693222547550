import type { Product } from './types';
import type { ProductGroupAction } from './actions';
import { createReducer } from 'utils/redux';
import { PRODUCTGROUP_PRODUCTS_UPDATED, productsUpdated } from './actions';

type ProductGroupPageState = {
  title: string | null;
  products: Array<Product> | null;
};

const initialState: ProductGroupPageState = {
  title: null,
  products: [],
};

export default createReducer<ProductGroupPageState, ProductGroupAction>(initialState, {
  [PRODUCTGROUP_PRODUCTS_UPDATED]: calculatedFieldsLoaded,
});

function calculatedFieldsLoaded(state: ProductGroupPageState, action: ReturnType<typeof productsUpdated>) {
  if (!state.products || !action.payload)
    return state;

  const calculatedProducts = action.payload;

  const updatedProducts = state.products.map(product => {
    const calculatedInfo = calculatedProducts.find(p => p.id === product.id);
    const calculatedInfoChanged = typeof product.price === 'undefined';

    if (!calculatedInfo)
      return { ...product, calculatedInfoChanged };

    return { ...product, ...calculatedInfo, calculated: true, calculatedInfoChanged };
  });

  return {
    ...state,
    products: updatedProducts,
  };
}
