exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".NotFound_block ul{padding-left:1.5em}.NotFound_action-list{margin:0}.NotFound_block .NotFound_loading{box-shadow:0 0 1px 0 rgba(0,0,0,.05);margin:.5em 0;width:100%}", ""]);

// exports
exports.locals = {
	"block": "NotFound_block",
	"action-list": "NotFound_action-list",
	"actionList": "NotFound_action-list",
	"loading": "NotFound_loading"
};