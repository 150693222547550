import type { Handler } from '../types';
import type { BackTo } from 'routes/types';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageComponentNames } from 'behavior/pages';
import { RouteName } from 'routes';
import { initPageContent, SystemPageData, SystemPage } from '../system';
import { loadCreateReturnOrderPageQuery, loadCreateReturnOrderPreviewPageQuery } from './queries';
import { getBackTo } from '../helpers';

const handler: Handler<ReturnOrderRouteData, CreateReturnOrderPage> = (routeData, state$, { api }) => {
  const { params: { id, originalOrderId: orderId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi<LoadCreateReturnOrderPreviewPageResponse>(loadCreateReturnOrderPreviewPageQuery).pipe(
      map(({ pages, settings: { documents: { returnOrder } } }) => {
        const page = pages.createDocBasedReturnOrder;

        if (!page)
          return null;

        const result = { page: initPageContent(page) as CreateReturnOrderPage };

        result.page.component = PageComponentNames.CreateDocBasedReturnOrder;
        result.page.returnReasons = { forOrder: [], forOrderLines: [] };
        result.page.fileUploadSettings = returnOrder.attachments;
        result.page.splitLinesSupported = returnOrder.splitLinesSupported;
        result.page.invoice = {
          id: '',
          orderId: '',
          canBeReturned: true,
          currency: { id: '', symbol: '', cultureName: '', decimalDigits: 1 },
          salesAgreementId: null,
          lines: Array.from(Array(3)).map(() => ({
            lineNo: '',
            availableReturnQuantity: 1,
            quantity: 1,
            uom: { minimumQuantity: 1, maximumQuantity: 1 },
          })),
        };

        return result;
      }),
    );
  }

  if (!id)
    return of(null);

  return api.graphApi<LoadCreateReturnOrderPageResponse>(loadCreateReturnOrderPageQuery, { id, orderId }).pipe(
    map(({ pages, documents: { invoices: { invoice } }, settings: { documents: { returnOrder } } }) => {
      const page = pages.createDocBasedReturnOrder;
      if (!page || !invoice)
        return null;

      const result = { page: initPageContent(page) as CreateReturnOrderPage };

      result.page.component = PageComponentNames.CreateDocBasedReturnOrder;

      result.page.backTo = getBackTo(state$, [
        RouteName.CreateDocBasedReturnOrder,
      ], routeData.params && routeData.params.language);

      result.page.returnReasons = returnOrder.reasons;
      result.page.fileUploadSettings = returnOrder.attachments;
      result.page.splitLinesSupported = returnOrder.splitLinesSupported;

      const lines = invoice.lines.itemLines.reduce<InvoiceLines[]>((resultLines, { product, ...line }) => {
        if ('sublines' in line) {
          if (line.sublines?.length)
            for (const subline of line.sublines)
              resultLines.push({
                ...subline,
                productId: product.id,
                title: line.title,
                variantTitle: subline.title,
                uom: getLineUom(subline, product),
                availableReturnQuantity: subline.availableReturnQuantity,
              });
        } else {
          resultLines.push({
            ...line,
            productId: product.id,
            uom: getLineUom(line, product),
            availableReturnQuantity: line.availableReturnQuantity,
          });
        }

        return resultLines;
      }, []);

      result.page.invoice = { ...invoice, lines };

      return result;
    }),
  );
};

export default handler;

function getLineUom(line: LineBase, product: Product) {
  if (!line.uom)
    return { minimumQuantity: 1, maximumQuantity: line.availableReturnQuantity };

  if (!product.uoms)
    return { ...line.uom, minimumQuantity: 1, maximumQuantity: line.availableReturnQuantity };

  const productUom = product.uoms.find(uom => uom.id === line.uom.id);
  const uom = { ...line.uom, ...productUom, maximumQuantity: line.availableReturnQuantity };

  if (uom.minimumQuantity == null)
    return { ...uom, minimumQuantity: uom.quantityStep };

  return uom as LineUom;
}

type ReturnOrderRouteData = {
  routeName: RouteName.CreateDocBasedReturnOrder;
  params: {
    id?: string;
    originalOrderId?: string;
    orderId?: string;
    previewToken?: string;
    language?: number | null;
  };
};

type CreateReturnOrderPageResponseBase = {
  pages: {
    createDocBasedReturnOrder: SystemPageData;
  };
};
type Reasons = {
  forOrder: {
    id: string;
    name: string;
  }[];
  forOrderLines: {
    id: string;
    name: string;
  }[];
};

type FileUploadSettings = {
  acceptedTypes: string[];
  allowMultiple: boolean;
  maxNameLength: number;
  maxSize: number;
};

type ReturnOrderSettings = {
  attachments: FileUploadSettings;
  reasons: Reasons;
  splitLinesSupported: boolean;
};

type LoadCreateReturnOrderPreviewPageResponse = CreateReturnOrderPageResponseBase & {
  settings: {
    documents: {
      returnOrder: Omit<ReturnOrderSettings, 'reasons'>;
    };
  };
};

type Product = {
  id: string;
  uoms: {
    id: string;
    minimumQuantity: number | null;
    quantityStep: number;
  }[] | null;
};

type LineBase = {
  lineNo: string;
  availableReturnQuantity: number;
  title: string | null;
  quantity: number;
  uom: {
    id: string;
    description?: string;
  };
  subtotal: number | null;
  salesAgreementId: string | null;
  salesAgreementLineId: string | null;
};

type SimpleLine = { product: Product } & LineBase;

type VariantLine = {
  product: Product;
  title: string | null;
  sublines: LineBase[];
};

type Invoice = {
  id: string;
  orderId: string;
  documentId?: string;
  canBeReturned: boolean | null;
  currency: {
    id: string;
    cultureName: string;
    symbol: string;
    decimalDigits: number;
  };
  salesAgreementId: string | null;
  lines: {
    itemLines: Array<SimpleLine | VariantLine>;
  };
};

type LoadCreateReturnOrderPageResponse = CreateReturnOrderPageResponseBase & {
  settings: {
    documents: {
      returnOrder: ReturnOrderSettings;
    };
  };
  documents: {
    invoices: {
      invoice: Invoice;
    };
  };
};

type LineUom = {
  minimumQuantity: number;
  maximumQuantity: number;
  id?: string;
  description?: string;
  quantityStep?: number;
};

type InvoiceLines =
  & Omit<LineBase, 'uom' | 'title' | 'subtotal' | 'salesAgreementId' | 'salesAgreementLineId'>
  & {
    productId?: string;
    title?: string | null;
    variantTitle?: string | null;
    uom?: LineUom;
    subtotal?: LineBase['subtotal'];
    salesAgreementId?: LineBase['salesAgreementId'];
    salesAgreementLineId?: LineBase['salesAgreementLineId'];
  };

type CreateReturnOrderPage = SystemPage & {
  component: PageComponentNames.CreateDocBasedReturnOrder;
  returnReasons: Reasons;
  fileUploadSettings: FileUploadSettings;
  splitLinesSupported: boolean;
  invoice: Omit<Invoice, 'lines'> & { lines: InvoiceLines[] };
  backTo?: BackTo;
};
