import { createElement, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb } from 'components/primitives/breadcrumb';
import { connect } from 'react-redux';
import { findNode, findCurrentPath, getCurrentNode, getMainNavigation } from './helpers';
import { BackTo } from 'components/primitives/links';
import BackLinkWrapper from './BackLinkWrapper';
import { iEquals } from 'utils/helpers';
import { Media } from 'components/responsive';

const PageBreadcrumb = ({
  navigation,
  url,
  showBreadcrumb,
  backLinkComponent,
  backTo,
  noTopMargin,
  currentLanguage,
}) => {
  const backToLink = useMemo(() => {
    if (!backTo)
      return null;

    return <BackTo to={backTo.routeData} url={backTo.url} texts={backLinkComponent && backLinkComponent.texts} />;
  }, [backTo, backLinkComponent]);

  if (!navigation || !navigation.length || !url)
    return null;

  if (!showBreadcrumb)
    return withoutBreadcrumb();

  const items = findCurrentPath(url, navigation, currentLanguage);
  if (!items) {
    if (backToLink)
      return wrapBackToLink();

    const parentLink = createParentLink();
    return parentLink && <Media lessThan="md">{parentLink}</Media>;
  }

  const back = backToLink || (
    backLinkComponent
      ? createElement(backLinkComponent, { node: getCurrentNode(items, url, navigation) })
      : null
  );
  const mobileBack = withoutBreadcrumb();

  return (
    <>
      {mobileBack && <Media lessThan="md">{mobileBack}</Media>}
      <Media greaterThanOrEqual="md">
        <Breadcrumb items={items} noTopMargin={noTopMargin} backLink={back} />
      </Media>
    </>
  );

  function withoutBreadcrumb() {
    return backToLink ? wrapBackToLink() : createParentLink();
  }

  function wrapBackToLink() {
    return <BackLinkWrapper noTopMargin={noTopMargin}>{backToLink}</BackLinkWrapper>;
  }

  // eslint-disable-next-line react/no-multi-comp
  function createParentLink() {
    if (!backLinkComponent)
      return null;

    const parentUrl = url.slice(1, url.lastIndexOf('/'));
    if (!parentUrl || iEquals(parentUrl, currentLanguage))
      return null;

    return (
      <BackLinkWrapper noTopMargin={noTopMargin}>
        {createElement(backLinkComponent, { node: findNode(navigation, parentUrl) })}
      </BackLinkWrapper>
    );
  }
};

PageBreadcrumb.propTypes = {
  navigation: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.shape({
      url: PropTypes.string,
    }),
    children: PropTypes.array,
  }).isRequired),
  url: PropTypes.string,
  showBreadcrumb: PropTypes.bool,
  backLinkComponent: PropTypes.any,
  backTo: PropTypes.shape({
    url: PropTypes.string,
    routeData: PropTypes.object.isRequired,
  }),
  noTopMargin: PropTypes.bool,
  currentLanguage: PropTypes.string,
};

export default connect(state => ({
  navigation: getMainNavigation(state),
  url: state.routing.location && state.routing.location.pathname,
  showBreadcrumb: state.settings.navigation && state.settings.navigation.breadcrumb,
  backTo: state.page.backTo,
  currentLanguage: state.localization.currentLanguage.cultureName,
}))(PageBreadcrumb);
