import { PageComponentNames } from 'behavior/pages';
import { map } from 'rxjs/operators';
import { initSystemPageContent } from '../system';
import { loadCreateReturnOrderPageQuery, loadCreateReturnOrderPreviewPageQuery } from './queries';

export default ({ params: { previewToken } }, _state$, { api }) =>
  api.graphApi(previewToken ? loadCreateReturnOrderPreviewPageQuery : loadCreateReturnOrderPageQuery).pipe(
    map(({ pages, settings: { documents: { returnOrder } } }) => {
      const page = pages.createDocFreeReturnOrder;
      if (!page)
        return null;

      page.products = {};
      page.returnReasons = returnOrder.reasons ?? { forOrder: [], forOrderLines: [] };
      page.fileUploadSettings = returnOrder.attachments;

      page.component = PageComponentNames.CreateDocFreeReturnOrder;

      return { page };
    }),
    initSystemPageContent(),
  );
