import type { Handler } from 'behavior/pages/types';
import type { SystemPage } from 'behavior/pages/system';
import type { RouteData } from 'routes';
import { tap } from 'rxjs/operators';
import { createSystemPageHandler } from 'behavior/pages/system';
import { SalesAgreementStatus } from 'behavior/salesAgreements';
import { RouteName } from 'routes';
import { PageComponentNames } from 'behavior/pages';

const systemHandler = createSystemPageHandler();

const salesAgreementsHandler: Handler<SalesAgreementsRouteData, SalesAgreementsPage> = (routeData, $state, dependencies) => {
  const search = routeData.params?.search;
  const activeOnly = routeData.params?.activeOnly;
  const previewToken = routeData.params?.previewToken;

  return systemHandler(routeData, $state, dependencies)
    .pipe(
      tap(data => {
        if (data && data.page) {
          data.page.search = search;
          data.page.activeOnly = activeOnly == null || activeOnly;

          if (previewToken) {
            data.page.agreements = {
              items: Array.from(Array(5)).map((_, index) => ({
                id: index.toString(),
                status: SalesAgreementStatus.Active,
                url: '',
                effectiveDate: '-',
                expirationDate: '-',
              })),
              loadedLength: 5,
            };
          }
        }
      }),
    );
};

export default salesAgreementsHandler;

type SalesAgreementsRouteData = RouteData & {
  routeName: RouteName.SalesAgreements;
  params: {
    search: string | null;
    activeOnly?: boolean;
    previewToken?: string;
  };
  options?: {
    backToScrollPosition?: {
      screenWidth: number;
      value: number;
    };
  };
  canonicalUrl?: string;
  language?: {
    name: string;
  };
};

type SalesAgreementsPage = SystemPage & {
  component: PageComponentNames;
  search?: string | null;
  activeOnly?: boolean;
  agreements?: {
    items: {
      id: string;
      status: SalesAgreementStatus;
      url: string;
      effectiveDate: string | null;
      expirationDate: string | null;
    }[];
    loadedLength: number;
  };
};