import { memo } from 'react';
import { useSelector } from 'react-redux';
import { renderRows, extendDefaultSettings } from 'components/containers';
import { mobile as defaultMobile } from './defaultContent';

const MobileHeaderTemplate = () => {
  const mobile = useSelector(state => state.header.mobile);

  return renderRows(mobile ?? extendDefaultSettings(defaultMobile));
};

export default memo(MobileHeaderTemplate);
