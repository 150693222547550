import styles from './ProductSuggestions.module.scss';
import { memo } from 'react';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useSelector } from 'react-redux';
import { Link } from 'components/primitives/links';
import { withAbilitiesAndRouteChecker } from 'components/primitives/checkers';
import { AbilityTo } from 'behavior/user/constants';
import { RouteName } from 'routes';
import { CartSuggestionIcon } from 'components/primitives/icons';
import { useSimpleTexts } from 'components/sanaText';

const ProductSuggestionsBlock = () => {
  const [getProductSuggestionsText] = useSimpleTexts(['ButtonAltText_GetProductSuggestions']).texts;
  const shoppingCartSuggestionPage = useSelector(({ settings }) => settings.shoppingCartSuggestionPage);

  if (shoppingCartSuggestionPage == null)
    return null;

  return (
    <Link
      title={getProductSuggestionsText}
      to={shoppingCartSuggestionPage.to}
      url={shoppingCartSuggestionPage.url}
      className={`${styles.hideLabel} ${styles.suggestionsBtn} ${btnStyles.btnWhite}`}
    >
      <CartSuggestionIcon className={styles.icon} aria-hidden />
    </Link>
  );
};

export default withAbilitiesAndRouteChecker(
  memo(ProductSuggestionsBlock), 
  [RouteName.BasketPage, RouteName.Checkout], 
  [AbilityTo.ViewProductSuggestions],
);
