import styles from './Summary.module.scss';
import PropTypes from 'prop-types';
import linkStyles from 'components/primitives/links/Link.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { FooterLinkStyleOption } from './constants';

const FooterLink = ({ style, textKey, ...props }) => {
  switch (style) {
    case FooterLinkStyleOption.ShowAsLink:
      return (
        <Link className={`${linkStyles.arrowed} ${styles.footerLink}`} {...props}>
          <SimpleText textKey={textKey} />
        </Link>
      );
    case FooterLinkStyleOption.ShowAsButton:
      return (
        <Link className={`${btnStyles.btn} ${btnStyles.btnBase} ${styles.footerBtn}`} {...props}>
          <span className="btn-cnt"><SimpleText textKey={textKey} /></span>
        </Link>
      );
    case FooterLinkStyleOption.ShowAsActionButton:
      return (
        <Link className={`${btnStyles.btn} ${btnStyles.btnBase} ${btnStyles.btnAction} ${styles.footerBtn}`} {...props}>
          <span className="btn-cnt"><SimpleText textKey={textKey} /></span>
        </Link>
      );
    default:
      return null;
  }
};

FooterLink.propTypes = {
  style: PropTypes.number.isRequired,
  textKey: PropTypes.string.isRequired,
};

export default FooterLink;
