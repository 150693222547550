exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".MediaGallery_media-gallery{min-width:70px;-webkit-user-select:none;-ms-user-select:none;user-select:none}.MediaGallery_preview-controls{display:none}.MediaGallery_preview-controls[hidden]{visibility:hidden}@media (min-width:env(--theme-breakpoints_Small,600)px),print{.MediaGallery_preview-controls{display:block;margin:2em 0;text-align:center}.MediaGallery_preview-controls .MediaGallery_link{-webkit-appearance:textfield;background:none;border:none;color:var(--theme-link_Color,#1f7bc9);cursor:pointer;display:inline-block;line-height:1.6;margin:0;padding:0 .8em 0;transition:color .15s;white-space:normal}html.pointer-mouse .MediaGallery_preview-controls .MediaGallery_link:hover{color:var(--theme-link_Hover_Color,#2289e3);text-decoration:underline}@supports (not (-webkit-hyphens:none)) and (not (-moz-appearance:none)) and (list-style-type:\"*\"){@media (min-width:env(--theme-breakpoints_Small,600)px) and (pointer:coarse),print and (pointer:coarse){html:not(.pointer-mouse) .MediaGallery_preview-controls .MediaGallery_link:active{color:var(--theme-link_Hover_Color,#2289e3);text-decoration:underline}}}@media not all and (min-resolution:.001dpcm){@media (pointer:coarse){.MediaGallery_preview-controls .MediaGallery_link:hover{-webkit-tap-highlight-color:var(--theme-link_Hover_Color,#2289e3)}}}.MediaGallery_preview-controls .MediaGallery_link:before{content:\"+ \"}.MediaGallery_preview-controls .MediaGallery_link-placeholder{display:inline-block;height:1.6em;width:16.6em}}", ""]);

// exports
exports.locals = {
	"media-gallery": "MediaGallery_media-gallery",
	"mediaGallery": "MediaGallery_media-gallery",
	"preview-controls": "MediaGallery_preview-controls",
	"previewControls": "MediaGallery_preview-controls",
	"link": "MediaGallery_link",
	"link-placeholder": "MediaGallery_link-placeholder",
	"linkPlaceholder": "MediaGallery_link-placeholder"
};