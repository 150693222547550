import styles from './ProductGroup.module.scss';

import PropTypes from 'prop-types';
import { Container, Row, Col } from 'components/primitives/grid';
import ProductGroupTabs from './ProductGroupTabs';
import ProductGroupProvider from './ProductGroupProvider';
import { trackProductListView, EventSource } from 'behavior/analytics';
import { useTracking } from '../productList/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { MediaGallery } from 'components/objects/mediaGallery';

const Page = ({ title, media, products }) => {
  const dispatch = useDispatch();
  useTracking(products,
    ({ products }) => dispatch(trackProductListView({ products, source: EventSource.ProductGroup })),
    [products],
  );

  const noImageLarge = useSelector(state => state.settings.product.noImage.large);

  return (
    <ProductGroupProvider key={title}>
      <Container>
        <Row className={`${styles.displayMdBlock} clearfix`}>
          <Col xs={{ size: 12, order: 2 }} md={6}
            className={`${styles.displayMdBlock} ${styles.floatLeft}`}
          >
            <MediaGallery items={media} noImageLarge={noImageLarge} />
          </Col>
          <Col xs={{ size: 12, order: 1 }} md={6}
            className={`${styles.displayMdBlock} ${styles.customOffset}`}
          >
            <div className={styles.overview}>
              <h1 className={styles.productTitle}>{title}</h1>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <ProductGroupTabs products={products} />
      </Container>
    </ProductGroupProvider>
  );
};

Page.propTypes = {
  title: PropTypes.string.isRequired,
  media: PropTypes.array,
  products: PropTypes.array,
};

export default Page;