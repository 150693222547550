import type { CalculatedProduct, ProductGroupLoadOptions } from './types';

export const PRODUCTGROUP_CALCULATED_FIELDS_REQUESTED = 'PRODUCTGROUP_CALCULATED_FIELDS_REQUESTED' as const;
export const requestCalculatedFields = (groupId: string, options?: ProductGroupLoadOptions) => ({
  type: PRODUCTGROUP_CALCULATED_FIELDS_REQUESTED,
  payload: { groupId, options },
});

export const PRODUCTGROUP_PRODUCTS_UPDATED = 'PRODUCTGROUP_PRODUCTS_UPDATED' as const;
export const productsUpdated = (products: CalculatedProduct[] | null) => ({
  type: PRODUCTGROUP_PRODUCTS_UPDATED,
  payload: products,
});

export type ProductGroupAction = ReturnType<
  | typeof requestCalculatedFields
  | typeof productsUpdated
>;
