import styles from './Thumbnails.module.scss';
import PropTypes from 'prop-types';
import { VideoProviderType } from 'utils/video';
import { MovieIcon } from 'components/primitives/icons/productIcons';
import YoutubeThumbnail from './YoutubeThumbnail';
import VimeoThumbnail from './VimeoThumbnail';

const VideoThumbnail = ({ item: { videoData, title } }) => {

  return (
    <>
      <div className={styles.videoIconWrapper}>
        <MovieIcon className={styles.videoIcon} />
      </div>
      {videoData.provider === VideoProviderType.Youtube
        ? <YoutubeThumbnail videoId={videoData.videoId} title={title} />
        : <VimeoThumbnail videoId={videoData.videoId} title={title} />
      }
    </>
  );
};

VideoThumbnail.propTypes = {
  item: PropTypes.shape({
    videoData: PropTypes.shape({
      provider: PropTypes.oneOf([VideoProviderType.Vimeo, VideoProviderType.Youtube]),
      videoId: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default VideoThumbnail;
