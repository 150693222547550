import styles from './ProductGroup.module.scss';
import tableStyles from 'components/primitives/table/Table.module.scss';
import { Table } from 'components/primitives/table';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { Col, Row } from 'components/primitives/grid';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import Stock from './Stock';
import ProductContext from './ProductContext';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { ProductPrice } from 'components/primitives/product';
import OrderBox from './OrderBox';
import AddToBasketButton from './AddToBasketButton';
import { WithProductClickTracking } from 'components/objects/analytics';
import { EventSource } from 'behavior/analytics';
import { Placeholder } from 'components/primitives/placeholders';
import useEditableSpecifications from '../product/useEditableSpecifications';
import { makeRichText } from 'utils/render';
import { getSpecificationHeaders } from './util';

const ProductGroupTabs = ({ products: productsInput }) => {
  const [canViewStock, showPrice, canOrderProducts] = useHasAbilities(AbilityTo.ViewStock, AbilityTo.ViewPrices, AbilityTo.OrderProducts);
  const tableHeadingPlaceholder = <Placeholder className={styles.tableHeadingPlaceholder} />;

  const specifications = useSelector(state => state.settings.product.productGrouping.specifications);
  const specificationHeaders = getSpecificationHeaders(specifications || [], productsInput || []);

  const products = productsInput.map(product => ({
    ...product,
    specifications: useEditableSpecifications(product.specifications),
  }));

  return (
    <Row className="print-no-flex">
      <Col xs={12} lg={12} xl={12}>
        <h2>
          <SimpleText textKey="ProductGroup_VariantsTabTitle" placeholder={tableHeadingPlaceholder} />
        </h2>
        <div className={styles.container}>
          {canOrderProducts && <AddToBasketButton />}
          <Table className={`${tableStyles.table} ${styles.variantsTable}`}>
            <thead>
              <tr>
                <th id="headerId">
                  <SimpleText textKey="ProductGrouping_VariantsTab_IdHeader" placeholder={tableHeadingPlaceholder} />
                </th>
                {specificationHeaders.map(({ key, name }) => (
                  <th key={key} id={key}>
                    {name}
                  </th>
                ))}
                {canViewStock &&
                  <th id="headerStockIndication">
                    <SimpleText textKey="ProductGrouping_VariantsTab_AvailabilityHeader" placeholder={tableHeadingPlaceholder} />
                  </th>
                }
                {showPrice &&
                  <th id="headerPrice" className={styles.priceCell}>
                    <SimpleText textKey="Price" placeholder={tableHeadingPlaceholder} />
                  </th>
                }
                {canOrderProducts && <th />}
              </tr>
            </thead>
            <tbody>
              {products.map(product => (
                <tr key={product.id}>
                  <td>
                    <WithProductClickTracking product={product} trackingSource={EventSource.ProductGroup}>
                      {handleClick => (
                        <Link onClick={handleClick} url={product.url} to={routesBuilder.forProduct(product.id)} className={styles.productId} title={product.title}>
                          {product.id}
                        </Link>
                      )}
                    </WithProductClickTracking>
                  </td>
                  {specificationHeaders.map(({ key }) => {
                    const specification = product.specifications && product.specifications.find(s => s.key === key);

                    return (
                      <td key={key}>
                        <span>
                          {specification ? makeRichText(specification.value) : <SimpleText textKey="ProductCompare_EmptySpecification" />}
                        </span>
                      </td>
                    );
                  })}
                  {canViewStock &&
                    <td>
                      <ProductContext.Provider value={product}>
                        <Stock />
                      </ProductContext.Provider>
                    </td>
                  }
                  {showPrice &&
                    <td className={`${styles.productAction} ${styles.priceCell}`}>
                      <ProductPrice salesPrice={product.price} basePrice={product.listPrice} />
                    </td>
                  }
                  {canOrderProducts &&
                    <td className={styles.orderBoxCell}>
                      <ProductContext.Provider value={product}>
                        <OrderBox
                          product={product}
                          className={styles.orderBox}
                          canOrderProducts={canOrderProducts}
                        />
                      </ProductContext.Provider>
                    </td>
                  }
                </tr>
              ))}
            </tbody>
          </Table>
          {canOrderProducts && <AddToBasketButton />}
        </div>
      </Col>
    </Row>
  );
};

ProductGroupTabs.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    title: PropTypes.string,
    price: PropTypes.number,
    listPrice: PropTypes.number,
    specifications: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      value: PropTypes.string,
    })),
  })),
};

export default memo(ProductGroupTabs);
