import type { Epic } from 'behavior/types';
import { ofType } from 'redux-observable';
import { filter, ignoreElements, tap, delay } from 'rxjs/operators';
import { ANALYTICS_DATALAYER_READY, AnalyticsAction } from './actions';
import { NAVIGATED, NavigatedAction } from 'behavior/routing';
import { skipIfPreview } from 'behavior/preview';
import { pushToDataLayer } from './dataLayer';
import { createPageViewPayload } from './payload';

const epic: Epic<AnalyticsAction | NavigatedAction> = (action$, state$) => action$.pipe(
  ofType(ANALYTICS_DATALAYER_READY, NAVIGATED),
  filter(_ => !!state$.value.analytics?.isDataLayerReady),
  skipIfPreview(state$),
  filter(action => {
    if (action.type === NAVIGATED)
      return !action.payload.routeData.options?.skipTracking;

    return !state$.value.routing.navigatingTo;
  }),
  delay(500), // Delay for page title to be set.
  tap(_ => {
    const inputData = {
      origin: window.location.origin,
      pageTitle: window.document.title,
    };
    pushToDataLayer(state$.value, inputData, createPageViewPayload);
  }),
  ignoreElements(),
);

export default epic;
