import type { ProductCategoryCollection, Product } from './types';
import type { SearchSuggestionsAction } from './actions';
import type { Epic } from 'behavior/types';
import type { LoadedSettings } from 'behavior/settings';
import { SEARCH_PRODUCT_SUGGESTIONS, productsSearchSuggestionsCompleted } from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, pluck } from 'rxjs/operators';
import { searchSuggestionsQuery } from './queries';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';

type ResponseProduct = {
  id: string;
  title: string | null;
  url: string | null;
  categoriesPaths: ProductCategoryCollection[];
  image: {
    small: string | null;
  } | null;
  productGroup?: {
    id: string;
    title: string | null;
    url: string;
    defaultImage: {
      small: string | null;
    } | null;
  } | null;
};

type SearchSuggestionsResponse = {
  catalog: {
    products: {
      products: ResponseProduct[];
    };
  };
};

const epic: Epic<SearchSuggestionsAction> = (action$, state$, { api, logger }) => action$.pipe(
  ofType(SEARCH_PRODUCT_SUGGESTIONS),
  pluck('payload'),
  switchMap(options => {
    const settings = state$.value.settings as LoadedSettings;
    const params = { options };
    const query = searchSuggestionsQuery({
      isProductGroupingEnabled: settings && settings.product.productGrouping.isEnabled,
    });

    return api.graphApi<SearchSuggestionsResponse>(query, params).pipe(
      map(mapSuggestions),
      retryWithToast(action$, logger),
    );
  }),
);

export default epic;

export function mapSuggestions(data: SearchSuggestionsResponse) {
  const suggestedProducts: Product[] = [];

  for (const product of data.catalog.products.products) {
    const suggestedProduct: Product = {
      ...product,
      routeData: routesBuilder.forProduct(product.id),
      imageUrl: product.image && product.image.small,
      productGroup: product.productGroup ? {
        id: product.productGroup.id,
        title: product.productGroup.title,
        url: product.productGroup.url,
        routeData: routesBuilder.forProductGroup(product.productGroup.id),
        imageUrl: product.productGroup.defaultImage && product.productGroup.defaultImage.small,
      } : null,
    };

    delete suggestedProduct.image;

    suggestedProducts.push(suggestedProduct);
  }

  return productsSearchSuggestionsCompleted(suggestedProducts);
}
