exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Tooltip_tooltip{text-align:inherit}.Tooltip_tooltip:focus{outline:1px dotted var(--theme-text_Color,grey)}.Tooltip_popup{background:var(--theme-popup_BackgroundColor,#fff);border:2px solid var(--theme-popup_BorderColor,#e6e6e6);box-shadow:0 0 5px rgba(0,0,0,.15);display:inline-block;line-height:1.4em;max-width:320px;opacity:0;overflow-y:auto;padding:10px;position:absolute;text-align:left;transition:visibility .3s,opacity .3s;visibility:hidden;width:270px;will-change:transform;z-index:9400000}.Tooltip_popup h4{margin:0;padding:0;text-transform:uppercase}.Tooltip_popup.Tooltip_opened{opacity:1;visibility:visible}.Tooltip_sign{color:var(--theme-link_Color,#1f7bc9);cursor:pointer;padding:.2em}", ""]);

// exports
exports.locals = {
	"tooltip": "Tooltip_tooltip",
	"popup": "Tooltip_popup",
	"opened": "Tooltip_opened",
	"sign": "Tooltip_sign"
};