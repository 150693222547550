import styles from './Error.module.scss';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useSimpleTexts } from 'components/sanaText';
import { Container } from 'components/primitives/grid';
import { Logo } from 'components/objects/logo';
import { Placeholder } from 'components/primitives/placeholders';
import { DangerAlert, alertStyles } from 'components/primitives/alerts';
import { useVerticalOverflowFix } from 'utils/hooks';
import { makeSimpleText } from 'utils/render';

const ErrorBoundaryPage = () => {
  const options = [
    'ErrorPage_PageErrorTitle',
    'ErrorBoundaryPage_Title',
    'ErrorBoundaryPage_ReloadMessageText',
    'ErrorPage_PageErrorReload_Text',
    'ErrorPage_PageErrorReload_Reload',
    'ErrorPages_HomepageLinkTemplate',
    'ErrorPages_HomepageLink',
    'ContactUs',
  ];
  options.disableInsiteEditor = true;
  const { texts: [
    pageTitle,
    title,
    reloadPageMsg,
    reloadTemplate,
    reload,
    homeTemplate,
    returnTxt,
    contactUs,
  ], loaded } = useSimpleTexts(options);

  const shopEmailAddress = useSelector(s => s.settings.shopEmailAddress);
  const placeholder = loaded ? '' : <Placeholder className={styles.loading} />;

  useVerticalOverflowFix();

  return (
    <div id="errorBoundaryPage" className={styles.errBoundary}>
      <Helmet>
        <title>{pageTitle || 'Requested page cannot be shown'}</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Container>
        <Logo className={styles.logo} />
        <h1>{placeholder || title || 'Sorry, the requested page cannot be shown'}</h1>
        <DangerAlert className={styles.block}>
          <div>
            {placeholder || makeSimpleText(reloadPageMsg) || 'Unfortunately there was an issue with retrieving the information for this page. Please, try the following:'}
          </div>
          <ul className={styles.actionList}>
            <li>
              {placeholder || makeSimpleText(
                reloadTemplate || '{0} the page',
                [<button className={alertStyles.link} onClick={_ => window.location.reload()}>{makeSimpleText(reload || 'Reload')}</button>],
                true,
              )}
            </li>
            <li>
              {placeholder || makeSimpleText(
                homeTemplate || '{0} to homepage.',
                [<a href="/">{makeSimpleText(returnTxt) || 'Return'}</a>],
                true,
              )}
            </li>
            <li>
              {placeholder || <a href={`mailto:${shopEmailAddress}`}>{makeSimpleText(contactUs || 'Contact us')}</a>}
            </li>
          </ul>
        </DangerAlert>
      </Container>
    </div>
  );
};

export default ErrorBoundaryPage;
