import { of } from 'rxjs';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { map } from 'rxjs/operators';
import { paymentCancelledQuery } from './queries';
import { loadSystemPageQuery, initSystemPageContent } from 'behavior/pages/system';

export default (routeData, _state$, { api }) => {
  const { params: { transactionId, previewToken } } = routeData;

  if (previewToken) {
    return api.graphApi(loadSystemPageQuery('paymentCancelled')).pipe(
      map(({ pages: { paymentCancelled } }) => ({
        page: {
          ...paymentCancelled,
          component: PageComponentNames.PaymentCancelled,
          transaction: { id: '' },
        },
      })),
      initSystemPageContent(),
    );
  }

  if (!transactionId)
    return of(null);

  return api.graphApi(paymentCancelledQuery, { id: transactionId }).pipe(
    map(({ pages: { paymentCancelled }, paymentTransaction: transaction }) => {
      if (transaction == null)
        return null;

      return ({
        page: {
          ...paymentCancelled,
          component: PageComponentNames.PaymentCancelled,
          transaction,
        },
      });
    }),
    initSystemPageContent(),
  );
};
