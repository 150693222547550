import styles from './Summary.module.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SimpleText } from 'components/sanaText';
import { joinClasses } from 'utils/helpers';
import ProductLine from './ProductLine';
import ConfigurationResultLine from './ConfigurationResultLine';
import Total from './Total';
import Footer from './Footer';
import { getThemeFontSizeClassName } from 'components/theme';

const Popup = ({
  basket,
  linesAmount,
  showTotals,
  pricesInclTax,
  currencyInfo,
  abilities,
  appliedSalesAgreementId,
  options,
}) => {
  if (!basket && !basket.productLines)
    return null;

  const [createOrderAbility, createQuoteAbility, viewUomAbility] = abilities;

  const {
    totalCount,
    calculated,
    totalPrice,
    totalPriceExcludingTax,
    editDocumentType,
    isBlocked,
    productLines,
  } = basket;

  const price = pricesInclTax ? totalPrice : totalPriceExcludingTax;
  const linesToTake = Math.min(linesAmount || 3, 3);
  const lines = productLines.list.slice(0, linesToTake);

  return (
    <>
      <h4
        className={joinClasses(
          styles.title,
          linesAmount ? styles.mainTitle : styles.altTitle,
          getThemeFontSizeClassName(options.headingThemeFontSize),
        )}
      >
        <SimpleText textKey={linesAmount ? 'ShoppingBasket_ProductAddedHeader' : 'SummaryPopup_Header'} />
      </h4>
      <div className={styles.productList}>
        {viewUomAbility &&
          lines.map((line, index) => {
            if (line.configuration)
              return (
                <ConfigurationResultLine
                  key={index}
                  line={line}
                  currencyInfo={currencyInfo}
                  viewUomAbility={viewUomAbility}
                  options={options}
                />
              );

            return (
              <ProductLine
                key={index}
                line={line}
                currencyInfo={currencyInfo}
                viewUomAbility={viewUomAbility}
                showTotals={showTotals}
                options={options}
              />
            );
          })
        }
      </div>
      {showTotals &&
        <Total totalCount={totalCount} calculated={calculated} currencyInfo={currencyInfo} price={price} />
      }
      {createOrderAbility && createQuoteAbility &&
        <Footer
          editDocumentType={editDocumentType}
          createOrderAbility={createOrderAbility}
          isBlocked={isBlocked}
          createQuoteAbility={createQuoteAbility}
          salesAgreementId={appliedSalesAgreementId}
          options={options}
        />
      }
    </>
  );
};

Popup.propTypes = {
  linesAmount: PropTypes.number,
  showTotals: PropTypes.bool,
  pricesInclTax: PropTypes.bool,
  currencyInfo: PropTypes.object,
  basket: PropTypes.shape({
    productLines: PropTypes.shape({
      list: PropTypes.array.isRequired,
    }),
    totalCount: PropTypes.number.isRequired,
    calculated: PropTypes.bool.isRequired,
    totalPrice: PropTypes.number,
    totalPriceExcludingTax: PropTypes.number,
    editDocumentType: PropTypes.any,
    isBlocked: PropTypes.bool.isRequired,
  }).isRequired,
  appliedSalesAgreementId: PropTypes.string,
  abilities: PropTypes.arrayOf(PropTypes.string),
  options: PropTypes.shape({
    headingThemeFontSize: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(({
  user: { pricesInclTax },
  settings: { currency },
  basket: { salesAgreementInfo },
}) => ({
  pricesInclTax,
  currencyInfo: currency,
  appliedSalesAgreementId: salesAgreementInfo?.id,
}))(Popup);
