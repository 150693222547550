import styles from './Error.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSimpleTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { useFocusActionBtn } from './hooks';
import ErrorId from './ErrorId';

const ErrorToastWithReload = ({ timeId }) => {
  const onClick = () => window.location.reload();

  const options = ['ReloadErrorMessageText', 'ButtonText_Reload'];
  options.disableInsiteEditor = true;

  const { texts: [msgText, btnText], loaded } = useSimpleTexts(options);

  const retryBtnRef = useRef();
  useFocusActionBtn(loaded && retryBtnRef);

  return (
    <div className={styles.body}>
      <p>
        {loaded
          ? msgText || 'Unfortunately there was an issue with retrieving the information for this page.'
          : <Placeholder className={styles.loading} />
        }
      </p>
      {timeId && <ErrorId id={timeId} />}
      {loaded
        ? (
          <button
            ref={retryBtnRef}
            className={`${linkStyles.link} ${styles.actionBtn}`}
            onClick={onClick}
          >
            {btnText || 'Reload'}
          </button>
        )
        : <Placeholder className={styles.loading} />
      }
    </div>
  );
};

ErrorToastWithReload.propTypes = {
  timeId: PropTypes.string,
};

export default ErrorToastWithReload;

export const preload = {
  texts: [
    'ReloadErrorMessageText',
    'ButtonText_Reload',
  ],
};
