import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { invoicePaymentPageQuery } from './queries';
import { loadSystemPageQuery, initSystemPageContent, SystemPageData, SystemPage } from 'behavior/pages/system';
import { Handler } from '../types';
import { Api } from '../../../../adk/webstore/types';
import { RouteName } from 'routes';

const handler: Handler<InvoicePaymentRouteData, InvoicePaymentPage> = (routeData, _, { api }) => {
  const { params: { orderId, previewToken } } = routeData;

  if (previewToken)
    return handlePreview(api);

  return api.graphApi<InvoicePaymentPageResponse>(invoicePaymentPageQuery, { orderId }).pipe(
    map(({
      pages: { invoicePayment: invoicePaymentPage },
      invoicePayment,
    }) => {
      if (!invoicePayment)
        return null;

      const { order, paymentMethods: { list: paymentMethods } } = invoicePayment;

      return {
        page: {
          component: PageComponentNames.InvoicePayment as const,
          order,
          paymentMethods: paymentMethods.map(({ info }) => info),
          ...invoicePaymentPage,
        },
      };
    }),
    initSystemPageContent(),
  );
};

export default handler;

type InvoicePaymentSystemPageResponse = {
  pages: {
    invoicePayment: SystemPageData;
  };
};

const handlePreview = (api: Api) => {
  return api.graphApi<InvoicePaymentSystemPageResponse>(loadSystemPageQuery('invoicePayment')).pipe(
    map(({ pages: { invoicePayment } }) => ({
      page: {
        component: PageComponentNames.InvoicePayment as const,
        order: {
          id: '',
          currency: {
            id: '',
            symbol: '',
            cultureName: '',
            decimalDigits: 0,
            separator: '',
          },
          orderDate: '-',
          referenceNo: '-',
          totalAmount: null,
        },
        paymentMethods: Array.from(Array(3)).map((_, index) => ({
          description: null,
          id: index.toString(),
          name: '',
          isDefault: false,
          imageUrl: null,
        })),
        ...invoicePayment,
      },
    })),
    initSystemPageContent(),
  );
};

type InvoicePaymentRouteData = {
  routeName: RouteName.InvoicePayment;
  params: {
    orderId?: string;
    previewToken?: string;
  };
};

type InvoicePaymentOrder = {
  id: string;
  currency: {
    id: string;
    cultureName: string;
    decimalDigits: number;
    separator: string;
    symbol: string;
  };
  orderDate: string;
  referenceNo: string | null;
  totalAmount: number | null;
};

type PaymentMethodInfo = {
  description: string | null;
  id: string;
  imageUrl: string | null;
  name: string;
  isDefault: boolean | null;
};

type InvoicePaymentPageResponse = {
  pages: {
    invoicePayment: SystemPageData;
  };
  invoicePayment: {
    order: InvoicePaymentOrder;
    paymentMethods: {
      list: {
        info: PaymentMethodInfo;
      }[];
    };
  };
};

type InvoicePaymentPage = SystemPage & {
  component: PageComponentNames.InvoicePayment;
  order: InvoicePaymentOrder;
  paymentMethods: PaymentMethodInfo[];
};
