import {
  VISUAL_DESIGNER_LOADED,
  VISUAL_DESIGNER_SET_PAGE_ERRORS,
  VISUAL_DESIGNER_RESOURCE_TEXTS_RECEIVED,
  VISUAL_DESIGNER_SET_SETTINGS,
  VISUAL_DESIGNER_SET_CUSTOMER_SEGMENT_TITLES,
  VISUAL_DESIGNER_SET_VIEWDATA,
} from './actions/general';
import { createReducer } from 'utils/redux';

const initialState = {
  initialized: false,
  pageErrors: {},
  resourceTexts: {},
  settings: {},
};

export default createReducer(initialState, {
  [VISUAL_DESIGNER_LOADED]: onLoaded,
  [VISUAL_DESIGNER_SET_PAGE_ERRORS]: onSetPageErrors,
  [VISUAL_DESIGNER_RESOURCE_TEXTS_RECEIVED]: onReceiveResourceTexts,
  [VISUAL_DESIGNER_SET_SETTINGS]: onSetSettings,
  [VISUAL_DESIGNER_SET_CUSTOMER_SEGMENT_TITLES]: onSetCustomerSegmentTitles,
  [VISUAL_DESIGNER_SET_VIEWDATA]: onSetViewData,
});

function onLoaded(state, action) {
  return {
    ...state,
    initialized: true,
    mode: action.payload.mode,
  };
}

function onSetPageErrors(state, action) {
  const { pageErrors } = action.payload;
  return {
    ...state,
    pageErrors,
  };
}

function onReceiveResourceTexts(state, action) {
  const { texts } = action.payload;
  return {
    ...state,
    resourceTexts: {
      ...state.resourceTexts,
      ...texts,
    },
  };
}

function onSetSettings(state, action) {
  const { settings } = action.payload;
  return {
    ...state,
    settings: {
      ...state.settings,
      ...settings,
    },
  };
}

function onSetCustomerSegmentTitles(state, action) {
  const { titles } = action.payload;
  return {
    ...state,
    customerSegmentTitles: { ...titles },
  };
}

function onSetViewData(state, action) {
  const { viewData } = action.payload;
  return {
    ...state,
    viewData,
  };
}
